import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, makeStyles, Typography, Box, Tooltip } from '@material-ui/core';
import HelpIcon from './icons/HelpIcon';
import Panel from './elements/Panel';
import InvoiceTableStepTwo from './tables/InvoiceTableStepTwo';
import { DIRECT_FINANCING, EARLY_PAYMENT } from '../helpers/constants';
import { cartSimulate, submitOrder } from '../store/actions/cartActions';
import OperationResume from './elements/OperationResume';
import CountryFormatHelper from './elements/CountryFormatHelper';
import BotAdvice from './elements/BotAdvice';
import LoadingButton from './elements/LoadingButton';
import SubmitedOrderChampagne from './dialogs/SubmitedOrderChampagne';
import SubmitedOrderErrorDialog from './dialogs/SubmitedOrderErrorDialog';
import { simulateDemo } from '../helpers/demoSimulation';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import { features } from '../config/features';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    padding: '0 30px 30px 30px',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'auto 270px',
    gridColumnGap: 17,
  },
  invoicesRoot: {
    gridColumn: '1 / 2',
    gridRow: '1 / 2',
  },
  resumeRoot: {
    gridColumn: '2 / 3',
    gridRow: '1 / 2',
  },
  annotation: {
    marginTop: 17,
    marginRight: convertSpacingToCss('md'),
  },
  tooltipIcon: {
    marginLeft: 6,
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  wordSeparator: {
    marginRight: 9,
  },
  botAdvice: {
    marginTop: 15,
  },
});

const CartStepTwo = ({ operationType, demo }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { countryId, id: businessId } = useSelector(
    state => state.businessState.business
  );
  const {
    simulation,
    cartInvoices,
    selectedCartInvoices,
    totalSelectedCartInvoices,
    orderSubmitIsLoading,
    orderSubmitError,
    submitedOrder,
    isFirstSimulation,
    directFinancingCount,
    earlyPaymentCount,
    totalCartInvoices,
  } = useSelector(state => state.cartState);

  const [showDialog, setShowDialog] = useState(null);
  const countryFeatures = features[countryId];
  const availableInvoiceCount =
    operationType === DIRECT_FINANCING
      ? directFinancingCount
      : earlyPaymentCount;

  useEffect(() => {
    if (submitedOrder) {
      setShowDialog('SubmitedOrderChampagne');
    }
  }, [submitedOrder]);

  useEffect(() => {
    if (orderSubmitError) {
      setShowDialog('SubmitedOrderErrorDialog');
    }
  }, [orderSubmitError]);

  useEffect(() => {
    if (!demo) {
      dispatch(
        cartSimulate(
          selectedCartInvoices,
          businessId,
          isFirstSimulation,
          operationType,
          availableInvoiceCount,
          totalCartInvoices
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCartInvoices, businessId, demo, dispatch]);

  const handleSubmitOrder = () => {
    if (!demo) {
      dispatch(
        submitOrder(
          businessId,
          selectedCartInvoices,
          cartInvoices.filter(invoice => invoice.preselected),
          operationType,
          simulation,
          availableInvoiceCount,
          totalCartInvoices
        )
      );
    } else {
      setShowDialog('SubmitedOrderChampagne');
    }
  };

  return (
    <>
      {showDialog === 'SubmitedOrderChampagne' && (
        <SubmitedOrderChampagne
          open={showDialog === 'SubmitedOrderChampagne'}
          handleClose={() => setShowDialog(null)}
        />
      )}

      {showDialog === 'SubmitedOrderErrorDialog' && (
        <SubmitedOrderErrorDialog
          open={showDialog === 'SubmitedOrderErrorDialog'}
          handleClose={() => setShowDialog(null)}
        />
      )}

      <Grid container className={classes.root}>
        <Grid item className={classes.invoicesRoot}>
          <Panel
            title="Elige las fechas de pago de tus facturas y envía tu solicitud a un ejecutivo."
            contentPadding="zero zero lg zero"
            titlePadding="xl xl lg"
          >
            <InvoiceTableStepTwo
              operationType={operationType}
              taxData={simulation}
              tableData={selectedCartInvoices}
              countryId={countryId}
            />
            <Grid container direction="row">
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  align="right"
                  component="div"
                >
                  <Box
                    fontWeight="fontWeightBold"
                    className={classes.tooltipIcon}
                  >
                    <span className={classes.wordSeparator}>Monto Total:</span>
                    <CountryFormatHelper
                      value={totalSelectedCartInvoices}
                      variant="currency"
                      countryId={countryId}
                      dataQa="invoice-table-step-two-monto-total-string"
                    />

                    {countryFeatures.invoicesTable.totalAmountTooltip && (
                      <Tooltip title="Valor incluye IVA">
                        <span>
                          <HelpIcon
                            color="secondary"
                            fontSize="small"
                            className={classes.tooltipIcon}
                          />
                        </span>
                      </Tooltip>
                    )}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color="secondary"
                  align="right"
                  variant="caption"
                  component="div"
                  className={classes.annotation}
                >
                  * Fecha de pago sugerida en relación al historial de
                  <br />
                  pago del proveedor. Esta fecha puede ser modificada.
                </Typography>
              </Grid>
            </Grid>
          </Panel>
        </Grid>
        <Grid item className={classes.resumeRoot}>
          <Panel
            title="Resumen de operación"
            titleAlign="center"
            titlePadding="lg lg zero"
            contentPadding="sm lg lg"
          >
            <OperationResume
              simulation={
                !demo ? simulation : simulateDemo(selectedCartInvoices)
              }
              showButton
              buttonComponent={
                <LoadingButton
                  variant="contained"
                  color="primary"
                  disabled={!selectedCartInvoices.length}
                  isLoading={orderSubmitIsLoading}
                  onClick={handleSubmitOrder}
                  fullWidth
                  data-qa="operation-resume-loading-button-action"
                >
                  Enviar solicitud
                </LoadingButton>
              }
            />
          </Panel>
          <div className={classes.botAdvice}>
            <BotAdvice
              title="¡No te cobramos otros gastos!"
              message="No cobramos gastos de comisión, transferencia, notificación o cobranzas."
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

CartStepTwo.propTypes = {
  demo: PropTypes.bool.isRequired,
  operationType: PropTypes.oneOf([DIRECT_FINANCING, EARLY_PAYMENT]).isRequired,
};

export default CartStepTwo;
