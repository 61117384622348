import {
  SET_LOCATION,
  WAS_IN,
  SET_UTM_INFO,
  SET_REFERRAL_CODE,
  SET_SUDO_USER,
} from './types';

export const setcountryId = countryId => dispatch => {
  dispatch({ type: SET_LOCATION, payload: { countryId } });
};

export const setWasInFinance = wasInFinance => dispatch => {
  dispatch({ type: WAS_IN, payload: { wasInFinance } });
};

export const setWasInLogin = wasInLogin => dispatch => {
  dispatch({ type: WAS_IN, payload: { wasInLogin } });
};

export const setUtmInfo = utmInfo => dispatch => {
  dispatch({ type: SET_UTM_INFO, payload: { utmInfo } });
};

export const setReferralCode = referralCode => dispatch => {
  dispatch({ type: SET_REFERRAL_CODE, payload: { referralCode } });
};

export const setIsSudoUser = isSudoUser => dispatch => {
  dispatch({ type: SET_SUDO_USER, payload: { isSudoUser } });
};
