/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  FETCH_DEBT_SERVICE_START,
  FETCH_DEBT_SERVICE_SUCCESS,
  FETCH_DEBT_SERVICE_FAILURE,
} from './types';

export const fetchDebtService = (entityId, entityType) => dispatch => {
  dispatch({ type: FETCH_DEBT_SERVICE_START });
  axios
    .get(`/api/${entityType}/${entityId}/debtservice`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_DEBT_SERVICE_SUCCESS,
        payload: resData,
      });
    })
    .catch(err => {
      dispatch({ type: FETCH_DEBT_SERVICE_FAILURE, payload: err });
    });
};
