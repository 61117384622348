// eslint-disable-next-line import/prefer-default-export
export const CL = {
  orderCreation: {
    totalTableTooltip: false,
    resumeInteresRateTooltip: false,
  },
  legal: {
    stakeholdersConjugal: true,
  },
  invoicesTable: {
    totalAmountTooltip: false,
  },
  bankAccountActions: {
    showCurrencyInput: false,
  },
  profileSidebar: [
    {
      key: 'profile',
      label: 'Perfil de Usuario',
      path: '/user/profile',
    },
    {
      key: 'bankAccounts',
      label: 'Cuentas Bancarias',
      path: '/user/profile/bankaccounts',
    },
    {
      key: 'sii',
      label: 'SII',
      path: '/user/profile/sii',
    },
  ],
};
