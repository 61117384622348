import React, { useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import DebtServiceCard from './elements/DebtServiceCard';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import { fetchDebtService } from '../store/actions/debtServiceActions';

const useStyles = makeStyles({
  root: {
    padding: convertSpacingToCss('lg xl'),
    justifyContent: 'space-between',
    display: 'flex',
    height: 161,
  },
});

const HomeCards = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const countryId = useSelector(
    state => state.businessState.business.countryId
  );
  const businessId = useSelector(state => state.businessState.business.id);

  useEffect(() => {
    dispatch(fetchDebtService(businessId, 'business'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid className={classes.root}>
      <Grid item container direction="row">
        <Grid item key="CARD_BURO_CREDITO">
          <DebtServiceCard countryId={countryId} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomeCards;
