import axios from 'axios';
import {
  FETCH_PAYMENTS_STATS_START,
  FETCH_PAYMENTS_STATS_SUCCESS,
  FETCH_PAYMENTS_STATS_ERROR,
} from './types';
import { t13s } from '../../translationKeys';
import { enqueueSnackbar } from './notificationActions';

// eslint-disable-next-line import/prefer-default-export
export const paymentsFetchStats = businessId => dispatch => {
  dispatch({
    type: FETCH_PAYMENTS_STATS_START,
  });
  axios
    .get(`/api/payment/business/${businessId}/stats`)
    .then(res => {
      const { pending, scheduled, delayed } = res.data;
      dispatch({
        type: FETCH_PAYMENTS_STATS_SUCCESS,
        payload: { stats: { pending, scheduled, delayed } },
      });
    })
    .catch(err => {
      dispatch({ type: FETCH_PAYMENTS_STATS_ERROR, payload: err });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.PAYMENTS_FETCH_STATS_ERROR,
          options: {
            variant: 'error',
          },
        })
      );
    });
};
