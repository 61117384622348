import axios from 'axios';
import {
  CREATE_PAYROLL_FAILURE,
  CREATE_PAYROLL_START,
  CREATE_PAYROLL_SUCCESS,
  DELETE_PAYROLL_FAILURE,
  DELETE_PAYROLL_START,
  DELETE_PAYROLL_SUCCESS,
  FETCH_PAYROLL_FAILURE,
  FETCH_PAYROLL_START,
  FETCH_PAYROLL_SUCCESS,
  FETCH_PAYROLLS_FAILURE,
  FETCH_PAYROLLS_START,
  FETCH_PAYROLLS_SUCCESS,
  RESET_PAYROLLS,
  UPDATE_PAYROLL_FAILURE,
  UPDATE_PAYROLL_START,
  UPDATE_PAYROLL_SUCCESS,
  PAY_PAYROLL_START,
  PAY_PAYROLL_SUCCESS,
  PAY_PAYROLL_FAILURE,
  FINANCING_PAYROLL_START,
  FINANCING_PAYROLL_SUCCESS,
  FINANCING_PAYROLL_FAILURE,
  RESET_FINANCING,
} from './types';
import { t13s } from '../../translationKeys';

import { enqueueSnackbar } from './notificationActions';

export const fetchPayrolls =
  ({ businessId, page, pageSize, option }) =>
  dispatch => {
    dispatch({ type: FETCH_PAYROLLS_START });
    axios
      .get(`/api/payment/business/${businessId}/payroll`, {
        params: { page, size: pageSize, option },
      })
      .then(res => {
        const { data: payrolls, pagination = {} } = res.data;
        dispatch({
          type: FETCH_PAYROLLS_SUCCESS,
          payload: { payrolls, pagination },
        });
      })
      .catch(err => {
        dispatch({ type: FETCH_PAYROLLS_FAILURE, payload: err });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.FETCH_PAYROLLS_FAILURE,
            options: {
              variant: 'error',
            },
          })
        );
      });
  };

export const createPayroll = (businessId, payrollData) => dispatch => {
  dispatch({ type: CREATE_PAYROLL_START });
  axios
    .post(`/api/payment/business/${businessId}/payroll`, { ...payrollData })
    .then(({ data }) => {
      dispatch({
        type: CREATE_PAYROLL_SUCCESS,
        payload: data,
      });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.CREATE_PAYROLL_SUCCESS,
          options: {
            variant: 'success',
          },
        })
      );
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({
        type: CREATE_PAYROLL_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.CREATE_PAYROLL_FAILURE,
          options: {
            variant: 'error',
          },
        })
      );
    });
};

export const fetchPayroll = (payrollId, businessId) => dispatch => {
  dispatch({ type: FETCH_PAYROLL_START });
  axios
    .get(`/api/payment/business/${businessId}/payroll/${payrollId}`)
    .then(({ data }) => {
      dispatch({
        type: FETCH_PAYROLL_SUCCESS,
        payload: data,
      });
    })
    .catch(err => {
      dispatch({ type: FETCH_PAYROLL_FAILURE, payload: err });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.FETCH_PAYROLL_FAILURE,
          options: {
            variant: 'error',
          },
        })
      );
    });
};

export const resetPayroll = () => dispatch => {
  dispatch({ type: RESET_PAYROLLS });
};

export const updatePayroll =
  (businessId, payrollId, payrollData) => dispatch => {
    dispatch({ type: UPDATE_PAYROLL_START });
    axios
      .put(
        `/api/payment/business/${businessId}/payroll/${payrollId}`,
        payrollData
      )
      .then(({ data }) => {
        dispatch({
          type: UPDATE_PAYROLL_SUCCESS,
          payload: data,
        });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.UPDATE_PAYROLL_SUCCESS,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: UPDATE_PAYROLL_FAILURE,
          payload: { errorCode },
        });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.UPDATE_PAYROLL_FAILURE,
            options: {
              variant: 'error',
            },
          })
        );
      });
  };

export const deletePayroll = (businessId, payrollId) => dispatch => {
  dispatch({ type: DELETE_PAYROLL_START });
  axios
    .delete(`/api/payment/business/${businessId}/payroll/${payrollId}`)
    .then(() => {
      dispatch({
        type: DELETE_PAYROLL_SUCCESS,
        payload: { payrollId },
      });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.DELETE_PAYROLL_SUCCESS,
          options: {
            variant: 'success',
          },
        })
      );
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({
        type: DELETE_PAYROLL_FAILURE,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.DELETE_PAYROLL_FAILURE,
          options: {
            variant: 'error',
          },
        })
      );
    });
};

export const payPayroll = (businessId, payrollIds) => async dispatch => {
  dispatch({ type: PAY_PAYROLL_START });

  try {
    const payResponse = await axios.put(
      `/api/payment/business/${businessId}/payroll/request-payment`,
      {
        payrollIds,
      }
    );
    const { data } = payResponse;

    dispatch({
      type: PAY_PAYROLL_SUCCESS,
      payload: data[0],
    });
  } catch (error) {
    const errorCode = error?.response?.data?.errorCode;

    dispatch({
      type: PAY_PAYROLL_FAILURE,
      payload: { errorCode },
    });
    dispatch(
      enqueueSnackbar({
        message: t13s.NOTIFICATION.PAY_PAYROLL_FAILURE,
        options: {
          variant: 'error',
        },
      })
    );
  }
};

export const requestFinancing = (businessId, payrollId) => async dispatch => {
  dispatch({ type: FINANCING_PAYROLL_START });

  try {
    const financingResponse = await axios.put(
      `/api/payment/business/${businessId}/payroll/${payrollId}/request-financing`
    );
    const { data } = financingResponse;

    dispatch({
      type: FINANCING_PAYROLL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const errorCode = error?.response?.data?.errorCode;

    dispatch({
      type: FINANCING_PAYROLL_FAILURE,
      payload: { errorCode },
    });
    dispatch(
      enqueueSnackbar({
        message: t13s.NOTIFICATION.FINANCING_PAYROLL_FAILURE,
        options: {
          variant: 'error',
        },
      })
    );
  }
};

export const resetFinancing = () => dispatch => {
  dispatch({ type: RESET_FINANCING });
};
