import React, { useEffect } from 'react';
import { Grid, makeStyles, Icon } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import PaymentsStat from './PaymentsStat';
import PaymentsInboxIcon from '../icons/PaymentsInboxIcon';
import PaymentsScheduledIcon from '../icons/PaymentsScheduledIcon';
import PaymentsLateIcon from '../icons/PaymentsLateIcon';
import { paymentsFetchStats } from '../../store/actions/paymentsStatsActions';

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridGap: 20,
    width: '100%',
    padding: '32px 20px',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: '144px',
  },
}));

const PaymentsStats = () => {
  const { countryId, id: businessId } = useSelector(
    state => state.businessState.business
  );
  const { stats, isStatsLoading } = useSelector(
    state => state.paymentsStatsState
  );
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(paymentsFetchStats(businessId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item className={classes.root}>
      <PaymentsStat
        title="Pendientes"
        icon={<Icon component={PaymentsInboxIcon} />}
        stats={stats?.pending}
        countryId={countryId}
        isLoading={isStatsLoading}
      />
      <PaymentsStat
        title="Programadas"
        icon={<Icon component={PaymentsScheduledIcon} />}
        stats={stats?.scheduled}
        countryId={countryId}
        isLoading={isStatsLoading}
      />
      <PaymentsStat
        title="Atrasadas"
        icon={<Icon component={PaymentsLateIcon} />}
        stats={stats?.delayed}
        countryId={countryId}
        isLoading={isStatsLoading}
      />
    </Grid>
  );
};

export default PaymentsStats;
