import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { AVAILABLE_COUNTRIES } from '../helpers/constants';
import {
  fetchPayrolls,
  deletePayroll,
  resetFinancing,
  resetPayroll,
} from '../store/actions/payrollActions';
import Loader from './elements/Loader';
import CustomButton from './elements/CustomButton';
import PayrollIcon from './icons/PayrollIcon';
import CountryFormatHelper from './elements/CountryFormatHelper';
import PayInvoicesStepper from './PayInvoicesStepper';
import { uniqueByKeepLast, removeByKey } from '../helpers/storeUtils';

const SCHEDULED_READY_TO_PAY = 'SCHEDULED_READY_TO_PAY';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  card: {
    borderRadius: 15,
    padding: 25,
    marginBottom: 30,
  },
  cardLeftPanel: {
    paddingRight: 25,
    width: '70%',
  },
  cardRightPanel: {
    paddingLeft: 25,
    width: '29%',
  },
  cardRightPanelContent: {
    height: '100%',
  },
  cardLeftPanelBottom: {
    alignItems: 'center',
    paddingLeft: 55,
    paddingTop: 20,
  },
  cardLeftPanelBottomDivider: {
    marginLeft: 30,
    marginRight: 30,
  },
  cardLeftPanelBottomStatus: {
    minWidth: 180,
  },
  avatar: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.primary.main,
    marginRight: 16,
  },
  button: {
    width: '100%',
  },
  title: {
    ...theme.typography.h5,
    paddingBottom: 32,
  },
  description: {
    ...theme.typography.h6,
    margin: 0,
  },
}));

const statusStyles = {
  SCHEDULED_READY_TO_PAY: { color: 'success.main' },
  FUNDING_AWAITING: { color: 'text.primary' },
  FUNDING_COMPLETE: { color: 'text.primary' },
  FUNDING_INCOMPLETE: { color: 'warning.main' },
  PAYMENT_IN_PROCESS: { color: 'text.primary' },
  PAYMENT_PARTIALLY_PAID: { color: 'secondary.main' },
  PAYMENT_PROBLEM: { color: 'secondary.main' },
  REJECTED: { color: 'secondary.main' },
  PAID: { color: 'success.main' },
  FINANCING_REQUESTED: { color: 'text.primary' },
  FINANCING_REJECTED: { color: 'secondary.main' },
};

const PaymentsScheduledCards = ({ countryId, businessId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const [actionsByPayroll, setActionsByPayroll] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [payrolls, setPayrolls] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedPayrollId, setSelectedPayrollId] = useState(null);
  const [showPayInvoicesStepper, setShowPayInvoicesStepper] = useState(false);
  const [dataInvoicesToPay, setDataInvoicesToPay] = useState({
    businessId,
    payrollId: 0,
    invoicesTotalAmount: 0,
    payrollPaymentDate: '',
    invoicesQuantities: 0,
    providersQuantities: 0,
  });

  const {
    payrolls: fetchedPayrolls,
    pagination: { page, totalPages, pageSize },
  } = useSelector(state => state.payrollsState);

  const handleClosePayModal = () => {
    setShowPayInvoicesStepper(false);
    dispatch(resetFinancing());
    dispatch(resetPayroll());
  };

  const handleClickMenu = (event, payrollId) => {
    event.persist();
    setAnchorEl(event.currentTarget);
    setSelectedPayrollId(payrollId);
  };

  const handleCloseMenu = event => {
    if (event) event.persist();
    setAnchorEl(null);
  };

  const handlePayInvoicesButton = ({
    payrollId,
    totalInvoices,
    totalSuppliers,
    paymentDate,
    summedAmount,
  }) => {
    setDataInvoicesToPay({
      payrollId,
      invoicesTotalAmount: summedAmount,
      payrollPaymentDate: paymentDate,
      invoicesQuantities: totalInvoices,
      providersQuantities: totalSuppliers,
    });
    setShowPayInvoicesStepper(true);
  };

  const payrollMenuButtons = [
    /* {
      id: 1,
      text: 'Editar Facturas',
      action: (payrollId, closeMenu) => {
        closeMenu();
      },
    }, */
    {
      id: 2,
      text: <Box color="warning.dark">Eliminar lista de facturas</Box>,
      action: (payrollId, closeMenu) => {
        dispatch(deletePayroll(businessId, payrollId));
        setPayrolls(removeByKey(payrolls, e => e.id, payrollId));
        closeMenu();
      },
      showCondition: status => status !== 'SCHEDULED_READY_TO_PAY',
    },
  ];

  const fetchMorePayrolls = () => {
    if (page < totalPages) {
      dispatch(
        fetchPayrolls({
          businessId,
          page: page + 1,
          pageSize,
          option: 'not-paid',
        })
      );
    }
  };

  useEffect(() => {
    setHasMore(page < totalPages);
  }, [page, totalPages]);

  useEffect(() => {
    if (businessId) {
      dispatch(fetchPayrolls({ businessId, pageSize, option: 'not-paid' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, dispatch]);

  useEffect(() => {
    const uniquePayrolls = uniqueByKeepLast(
      [...payrolls, ...fetchedPayrolls],
      e => e.id
    );
    const actionsByPayroll = uniquePayrolls.map(payroll => {
      const { id, status } = payroll;
      const totalActions = payrollMenuButtons.filter(({ showCondition }) => {
        if (showCondition === undefined) return true;
        if (showCondition(status)) return false;
        return true;
      }).length;
      return [id, totalActions];
    });
    setActionsByPayroll(Object.fromEntries(actionsByPayroll));
    setPayrolls(uniquePayrolls);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedPayrolls]);
  return (
    <Box className={classes.root}>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {payrollMenuButtons.map(({ id, text, action, showCondition }) => {
          if (!selectedPayrollId) return null;
          const { status } =
            fetchedPayrolls.find(payroll => payroll.id === selectedPayrollId) ||
            false;
          if (typeof showCondition !== 'undefined' && showCondition(status)) {
            return null;
          }
          return (
            <MenuItem
              key={id}
              onClick={() => action(selectedPayrollId, handleCloseMenu)}
            >
              {text}
            </MenuItem>
          );
        })}
      </Menu>

      <Typography
        variant="body1"
        color="textPrimary"
        component="div"
        className={classes.title}
      >
        <Box fontWeight="fontWeightBold">
          Selecciona el listado de facturas que quieras pagar
        </Box>
      </Typography>

      <InfiniteScroll
        dataLength={payrolls.length}
        next={fetchMorePayrolls}
        hasMore={hasMore}
        loader={<Loader />}
      >
        {payrolls.map(
          ({
            id: payrollId,
            name,
            paymentDate,
            status,
            totalInvoices,
            totalSuppliers,
            summedAmount,
          }) => (
            <Card key={payrollId} className={classes.card}>
              <Grid container justifyContent="space-between">
                <Grid item className={classes.cardLeftPanel}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <Avatar className={classes.avatar}>
                        <Box color="grey.900">
                          <PayrollIcon color="inherit" />
                        </Box>
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Typography variant="h5" component="div">
                        <Box fontWeight="fontWeightBold">{name}</Box>
                      </Typography>
                      <Link
                        variant="subtitle1"
                        component="button"
                        onClick={() => history.push(`/payrolls/${payrollId}`)}
                        color="inherit"
                      >
                        <Box fontWeight="fontWeightBold">Ver Facturas</Box>
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.cardLeftPanelBottom}>
                    <Grid item className={classes.cardLeftPanelBottomStatus}>
                      <Typography variant="subtitle1" color="text.secondary">
                        Estado
                      </Typography>
                      <Typography variant="subtitle1" component="div">
                        <Box
                          fontWeight="fontWeightBold"
                          color={
                            status in statusStyles
                              ? statusStyles[status].color
                              : null
                          }
                        >
                          {t(status)}
                        </Box>
                      </Typography>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={classes.cardLeftPanelBottomDivider}
                    />
                    <Grid item>
                      <Typography variant="subtitle1" color="text.secondary">
                        Facturas
                      </Typography>
                      <Typography variant="subtitle1">
                        {totalInvoices}
                      </Typography>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={classes.cardLeftPanelBottomDivider}
                    />
                    <Grid item>
                      <Typography variant="subtitle1" color="text.secondary">
                        Proveedores
                      </Typography>
                      <Typography variant="subtitle1">
                        {totalSuppliers}
                      </Typography>
                    </Grid>
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={classes.cardLeftPanelBottomDivider}
                    />
                    <Grid item>
                      <Typography variant="subtitle1" color="text.secondary">
                        Fecha de Pago
                      </Typography>
                      <Typography variant="subtitle1">
                        <CountryFormatHelper
                          value={paymentDate}
                          variant="longdate"
                          countryId={countryId}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Divider orientation="vertical" flexItem />

                <Grid container item className={classes.cardRightPanel}>
                  <Grid item xs>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      className={classes.cardRightPanelContent}
                    >
                      <Grid item>
                        <Typography variant="subtitle1" color="text.secondary">
                          Total
                        </Typography>
                        <Typography variant="h4" component="div">
                          <Box fontWeight="fontWeightMedium">
                            <CountryFormatHelper
                              value={summedAmount}
                              variant="currency"
                              countryId={countryId}
                            />
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        {status === SCHEDULED_READY_TO_PAY && (
                          <CustomButton
                            variant="orange-lined"
                            className={classes.button}
                            onClick={() =>
                              handlePayInvoicesButton({
                                payrollId,
                                totalInvoices,
                                totalSuppliers,
                                paymentDate,
                                summedAmount,
                              })
                            }
                          >
                            Pagar Facturas
                          </CustomButton>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    {actionsByPayroll[payrollId] > 0 && (
                      <IconButton
                        onClick={event => handleClickMenu(event, payrollId)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          )
        )}
      </InfiniteScroll>

      <PayInvoicesStepper
        open={showPayInvoicesStepper}
        closeModal={handleClosePayModal}
        dataInvoicesToPay={dataInvoicesToPay}
      />
    </Box>
  );
};

PaymentsScheduledCards.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  businessId: PropTypes.number.isRequired,
};

export default PaymentsScheduledCards;
