import {
  FETCH_ORDER_DETAIL_START,
  FETCH_ORDER_DETAIL_SUCCESS,
  FETCH_ORDER_DETAIL_ERROR,
  FETCH_ORDER_INVOICES_START,
  FETCH_ORDER_INVOICES_SUCCESS,
  FETCH_ORDER_INVOICES_ERROR,
  LOCAL_PURGE,
  RESET_ORDER_DETAILS,
  FETCH_INVOICE_DOCUMENTS_START,
  FETCH_INVOICE_DOCUMENTS_SUCCESS,
  FETCH_INVOICE_DOCUMENTS_ERROR,
  DOWNLOAD_INVOICE_DOCUMENTS_START,
  DOWNLOAD_INVOICE_DOCUMENTS_SUCCESS,
  DOWNLOAD_INVOICE_DOCUMENTS_ERROR,
} from '../actions/types';
import { t13s } from '../../translationKeys';

const initialState = {
  resume: {},
  type: '',
  orderDetailIsLoading: false,
  orderDetailError: null,

  invoiceDocuments: [],
  fetchInvoiceDocumentsIsLoading: false,
  fetchInvoiceDocumentsError: null,

  downloadInvoiceDocumentsIsLoading: false,
  invoiceDocumentWasDownloaded: false,
  downloadInvoiceDocumentsError: null,

  invoices: [],
  pagination: { totalCount: 0, pageSize: 20, page: 1 },
  orderInvoicesIsLoading: false,
  orderInvoicesError: null,
};

const fetchOrderDetailStart = state => {
  return {
    ...state,
    orderDetailIsLoading: true,
  };
};

const fetchOrderDetailSuccess = (state, payload) => {
  return {
    ...state,
    orderDetailIsLoading: false,
    ...payload,
  };
};

const fetchOrderDetailError = (state, payload) => {
  return {
    ...state,
    resume: {},
    type: '',
    orderDetailIsLoading: false,
    orderDetailError: payload.error,
  };
};

const fetchOrderInvoicesStart = state => {
  return {
    ...state,
    orderInvoicesIsLoading: true,
  };
};

const fetchOrderInvoicesSuccess = (state, payload) => {
  return {
    ...state,
    orderInvoicesIsLoading: false,
    ...payload,
  };
};

const fetchOrderInvoicesError = (state, payload) => {
  return {
    ...state,
    invoices: [],
    orderInvoicesIsLoading: false,
    orderInvoiceslError: payload.error,
  };
};

const fetchInvoiceDocumentsStart = state => {
  return {
    ...state,
    fetchInvoiceDocumentsIsLoading: true,
    fetchInvoiceDocumentsError: null,
  };
};

const fetchInvoiceDocumentsSuccess = (state, payload) => {
  return {
    ...state,
    fetchInvoiceDocumentsIsLoading: false,
    invoiceDocuments: payload.invoiceDocuments,
  };
};

const fetchInvoiceDocumentsError = (state, payload) => {
  return {
    ...state,
    fetchInvoiceDocumentsError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
    fetchInvoiceDocumentsIsLoading: false,
  };
};

const downloadInvoiceDocumentsStart = state => {
  return {
    ...state,
    downloadInvoiceDocumentsIsLoading: true,
    invoiceDocumentWasDownloaded: false,
    downloadInvoiceDocumentsError: null,
  };
};

const downloadInvoiceDocumentsSuccess = state => {
  return {
    ...state,
    downloadInvoiceDocumentsIsLoading: false,
    invoiceDocumentWasDownloaded: true,
  };
};

const downloadInvoiceDocumentsError = (state, payload) => {
  return {
    ...state,
    downloadInvoiceDocumentsError:
      payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
    downloadInvoiceDocumentsIsLoading: false,
    invoiceDocumentWasDownloaded: true,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ORDER_DETAIL_START:
      return fetchOrderDetailStart(state);
    case FETCH_ORDER_DETAIL_SUCCESS:
      return fetchOrderDetailSuccess(state, payload);
    case FETCH_ORDER_DETAIL_ERROR:
      return fetchOrderDetailError(state, payload);
    case FETCH_ORDER_INVOICES_START:
      return fetchOrderInvoicesStart(state);
    case FETCH_ORDER_INVOICES_SUCCESS:
      return fetchOrderInvoicesSuccess(state, payload);
    case FETCH_ORDER_INVOICES_ERROR:
      return fetchOrderInvoicesError(state, payload);
    case FETCH_INVOICE_DOCUMENTS_START:
      return fetchInvoiceDocumentsStart(state);
    case FETCH_INVOICE_DOCUMENTS_SUCCESS:
      return fetchInvoiceDocumentsSuccess(state, payload);
    case FETCH_INVOICE_DOCUMENTS_ERROR:
      return fetchInvoiceDocumentsError(state, payload);
    case DOWNLOAD_INVOICE_DOCUMENTS_START:
      return downloadInvoiceDocumentsStart(state);
    case DOWNLOAD_INVOICE_DOCUMENTS_SUCCESS:
      return downloadInvoiceDocumentsSuccess(state);
    case DOWNLOAD_INVOICE_DOCUMENTS_ERROR:
      return downloadInvoiceDocumentsError(state, payload);
    case RESET_ORDER_DETAILS:
      return initialState;
    case LOCAL_PURGE:
      return initialState;
    default:
      return state;
  }
};
