import axios from 'axios';
import {
  FETCH_SUPPLIERS_START,
  FETCH_SUPPLIERS_SUCCESS,
  FETCH_SUPPLIERS_ERROR,
  UPDATE_SUPPLIER_START,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_ERROR,
  SET_SUPPLIER,
} from './types';

import { enqueueSnackbar } from './notificationActions';
import { t13s } from '../../translationKeys';

const parseSupplier = supplier => {
  const {
    id,
    businessId,
    supplierId,
    paymentTerm,
    Business: { name, identifier, countryId },
  } = supplier;
  return {
    id,
    businessId,
    supplierId,
    countryId,
    paymentTerm,
    name: name || '',
    identifier: identifier || '',
  };
};

export const fetchSuppliers =
  ({ businessId, pageSize, page, name }) =>
  dispatch => {
    dispatch({ type: FETCH_SUPPLIERS_START });
    axios
      .get(`/api/payment/business/${businessId}/supplier`, {
        params: { size: pageSize, page, name },
      })
      .then(res => {
        const { data: suppliers, pagination } = res.data;
        dispatch({
          type: FETCH_SUPPLIERS_SUCCESS,
          payload: {
            suppliers: suppliers.map(supplier => parseSupplier(supplier)),
            pagination,
          },
        });
      })
      .catch(err => {
        dispatch({ type: FETCH_SUPPLIERS_ERROR, payload: err });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.FETCH_SUPPLIERS_ERROR,
            options: {
              variant: 'error',
            },
          })
        );
      });
  };

export const updateSupplier =
  (businessId, supplierId, supplierData) => dispatch => {
    dispatch({ type: UPDATE_SUPPLIER_START });
    axios
      .put(
        `/api/payment/business/${businessId}/supplier/${supplierId}`,
        supplierData
      )
      .then(res => {
        const { data: supplier } = res;
        dispatch({
          type: UPDATE_SUPPLIER_SUCCESS,
          payload: parseSupplier(supplier),
        });
      })
      .catch(err => {
        dispatch({ type: UPDATE_SUPPLIER_ERROR, payload: err });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.UPDATE_SUPPLIER_ERROR,
            options: {
              variant: 'error',
            },
          })
        );
      });
  };

export const setSupplier = supplier => dispatch => {
  dispatch({ type: SET_SUPPLIER, payload: supplier });
};
