import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Typography, Box, Button } from '@material-ui/core';
import clsx from 'clsx';
import Card from './Card';
import CountryFormatHelper from './CountryFormatHelper';
import { AVAILABLE_COUNTRIES } from '../../helpers/constants';
import PaymentsStatSkeleton from './PaymentsStatSkeleton';

const FILTER_BUTTON_TEXT = {
  all: 'Todas',
};

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    padding: '24px 16px 0 16px',
    boxSizing: 'border-box',
  },
  top: {
    margin: '0 0 24px 0',
  },
  title: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  price: {
    fontSize: '32px',
    lineHeight: '48px',
  },
  filterButton: {
    minWidth: '50px',
    background: 'transparent',
    color: theme.palette.text.secondary,
    borderRadius: 20,
    border: 0,
    fontSize: '10px',
    lineHeight: '16px',
    textAlign: 'center',
    padding: '3px 0px',
    cursor: 'pointer',
    '&.active': {
      background: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  icon: {
    width: 48,
    height: 48,
    margin: '0 16px 0 0',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}));

const PaymentsStat = props => {
  const { title, icon, stats, countryId, isLoading } = props;
  const [daysToFilter, setDaysToFilter] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (stats) {
      const filters = Object.keys(stats);
      setDaysToFilter(filters);
      setSelectedFilter(filters[0]);
    }
  }, [stats]);

  const handleFilter = (event, filter) => {
    event.preventDefault();
    setSelectedFilter(filter);
  };

  const isValidNumber = number => !Number.isNaN(Number(number));

  const translateKeyToButtonText = key => {
    const wordToRemove = ['days', 'day'].join('|');
    let translatedKey = key.replace(new RegExp(`(${wordToRemove})`, 'g'), '');
    if (!isValidNumber(translatedKey)) {
      translatedKey = FILTER_BUTTON_TEXT[translatedKey];
    } else {
      translatedKey = Number(translatedKey);
    }
    return translatedKey;
  };

  return (
    <Grid item>
      <Card variant="white" border className={classes.card}>
        {stats && daysToFilter && !isLoading ? (
          <Box>
            <Grid
              item
              justifyContent="space-between"
              alignItems="center"
              container
              className={classes.top}
            >
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.title}
                  component="div"
                >
                  <Box fontWeight="fontWeightMedium">{title}</Box>
                </Typography>
              </Grid>
              <Grid item>
                <Grid item container>
                  {daysToFilter.map(filter => (
                    <Grid key={filter} item>
                      <Button
                        key={filter}
                        variant="text"
                        color="inherit"
                        className={clsx(classes.filterButton, {
                          active: filter === selectedFilter,
                        })}
                        onClick={event => handleFilter(event, filter)}
                      >
                        <Box
                          fontWeight="fontWeightBold"
                          fontSize="body2.fontSize"
                        >
                          {translateKeyToButtonText(filter)}&nbsp;
                          {isValidNumber(translateKeyToButtonText(filter)) &&
                            'días'}
                        </Box>
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item>
                <Box className={classes.icon}>{icon}</Box>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.price}
                  component="div"
                >
                  <Box fontWeight="fontWeightMedium">
                    <CountryFormatHelper
                      value={stats[selectedFilter]}
                      countryId={countryId}
                      variant="currency"
                    />
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <PaymentsStatSkeleton />
        )}
      </Card>
    </Grid>
  );
};

PaymentsStat.defaultProps = {
  stats: null,
};

PaymentsStat.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  stats: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool.isRequired,
};

export default PaymentsStat;
