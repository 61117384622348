import React from 'react';
import { Typography, Box } from '@material-ui/core';

const getRender = (type, code, invoice) => {
  const attributeExtra = invoice.extraData.find(col => col.code === code);
  const value = attributeExtra?.value;
  switch (type) {
    case 'string': {
      return (
        <Typography variant="body1" color="textSecondary" component="div">
          <Box
            fontWeight="fontWeightBold"
            data-qa={`extra-column-${code
              .toLowerCase()
              .replace('_', '-')}-row-data`}
          >
            {value}
          </Box>
        </Typography>
      );
    }
    default:
      return null;
  }
};

const getWidth = code => {
  let width = '';
  switch (code) {
    case 'INVOICE_TYPE':
      width = '100px';
      break;
    case 'INVOICE_STATE':
      width = '150px';
      break;
    default:
      break;
  }
  return width;
};

// eslint-disable-next-line import/prefer-default-export
export const getExtraColumns = extraColumns => {
  if (!extraColumns) return null;
  return extraColumns.map((column, index) => {
    const { code, type, label } = column;
    return {
      title: label,
      field: `extraData[${index}].value`,
      type,
      width: getWidth(code),
      render: rowData => getRender(type, code, rowData),
    };
  });
};
