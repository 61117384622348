import axios from 'axios';
import {
  FETCH_STAKEHOLDERS_START,
  FETCH_STAKEHOLDERS_ERROR,
  FETCH_STAKEHOLDERS_SUCCESS,
  EDIT_STAKEHOLDERS_START,
  EDIT_STAKEHOLDERS_SUCCESS,
  EDIT_STAKEHOLDERS_ERROR,
} from './types';
import { enqueueSnackbar } from './notificationActions';
import { t13s } from '../../translationKeys';
import {
  resetDocumentsVerify,
  verifyStatusDocuments,
} from './documentsActions';

const entityName = 'stakeholder';
export const fetchStakeholders = businessId => dispatch => {
  dispatch({ type: FETCH_STAKEHOLDERS_START });
  dispatch(resetDocumentsVerify(entityName));
  axios
    .get(`/api/business/${businessId}/stakeholder`)
    .then(res => {
      const stakeholders = res.data;
      dispatch({
        type: FETCH_STAKEHOLDERS_SUCCESS,
        payload: { stakeholders },
      });
      stakeholders.forEach(stakeholder => {
        dispatch(verifyStatusDocuments(stakeholder.stakeholderId, entityName));
      });
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({ type: FETCH_STAKEHOLDERS_ERROR, payload: { errorCode } });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.FETCH_STAKEHOLDERS_ERROR,
          options: {
            variant: 'error',
          },
        })
      );
    });
};

export const editStakeholders = stakeholder => dispatch => {
  const { businessId, stakeholderId, ...rest } = stakeholder;
  dispatch({ type: EDIT_STAKEHOLDERS_START });
  axios
    .put(`/api/business/${businessId}/stakeholder/${stakeholderId}`, {
      ...rest,
    })
    .then(res => {
      dispatch({
        type: EDIT_STAKEHOLDERS_SUCCESS,
        payload: { updatedStakeholder: res.data },
      });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.EDIT_STAKEHOLDERS_SUCCESS,
          options: {
            variant: 'success',
          },
        })
      );
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({ type: EDIT_STAKEHOLDERS_ERROR, payload: { errorCode } });
    });
};
