import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { AVAILABLE_COUNTRIES } from '../helpers/constants';
import PaymentsInvoiceTable from './tables/PaymentsInvoiceTable';
import PaymentsInvoiceTableToolbar from './elements/PaymentsInvoiceTableToolbar';
import Card from './elements/Card';
import {
  paymentsFetchInvoices,
  paymentsRestoreInvoice,
  paymentsSetPagination,
} from '../store/actions/paymentsActions';

const TABLE_NAME = 'archived';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
  },
  tableInvoicesContainer: {
    alignContent: 'flex-start',
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    height: '100%',
  },
}));

const PaymentsArchivedTable = React.memo(props => {
  const { countryId, businessId } = props;
  const { pagination } = useSelector(state => state.paymentsState);
  const { page, pageSize } = pagination;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [search, setSearch] = useState(null);

  const actionsButtons = [
    {
      text: 'Devolver a mis facturas',
      action: ({ invoice, closeMenu }) => {
        dispatch(paymentsRestoreInvoice(businessId, [invoice], 'archived'));
        closeMenu();
      },
    },
  ];

  const handleSearchChange = value => {
    dispatch(
      paymentsSetPagination({
        ...pagination,
        page: 1,
      })
    );
    setSearch(value);
  };

  const handleUpdateInvoices = (page, pageSize) => {
    dispatch(
      paymentsFetchInvoices({
        businessId,
        option: TABLE_NAME,
        pageSize,
        page: page + 1,
        search,
      })
    );
  };

  useEffect(() => {
    dispatch(
      paymentsFetchInvoices({
        businessId,
        option: TABLE_NAME,
        pageSize,
        page,
        search,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch]);

  return (
    <Grid container item className={classes.tableInvoicesContainer}>
      <PaymentsInvoiceTableToolbar
        title="Historial de facturas archivadas"
        description="En este historial podrás encontrar las facturas que fueron archivadas o reportadas."
        onChangeSearch={handleSearchChange}
      />

      <Card variant="white" border className={classes.card}>
        <PaymentsInvoiceTable
          actionsButtons={actionsButtons}
          countryId={countryId}
          handleUpdateInvoices={handleUpdateInvoices}
        />
      </Card>
    </Grid>
  );
});

PaymentsArchivedTable.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  businessId: PropTypes.number.isRequired,
};

export default PaymentsArchivedTable;
