import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import BaseDialog from './BaseDialog';
import LoadingButton from '../elements/LoadingButton';
import { t13s } from '../../translationKeys';
import logoSii from '../../assets/logosii.png';
import logoSat from '../../assets/logosat.svg';
import { COUNTRY_CODE_CL, COUNTRY_CODE_MX } from '../../helpers/constants';
import { createTaxUser } from '../../store/actions/taxServiceActions';
import AlertForm from '../elements/AlertForm';

const useStyles = makeStyles({
  form: {
    width: 300,
  },
});
const TaxServiceCredentialsDialog = ({ open, handleCloseDialog }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { identifier, id, countryId } = useSelector(
    state => state.authState.business
  );
  const createTaxUserIsLoading = useSelector(
    state => state.taxServiceState.createTaxServiceCredentialIsLoading
  );
  const createTaxUserError = useSelector(
    state => state.taxServiceState.createTaxServiceCredentialError
  );

  const [password, setPassword] = useState('');

  useEffect(() => {
    setPassword('');
  }, [open]);

  const handleSubmitCredential = () => {
    const credential = {
      user: identifier,
      password,
    };

    dispatch(createTaxUser(id, credential));
  };

  const getIcon = () => {
    let topIcon;
    let topIconWidth;
    switch (countryId) {
      case COUNTRY_CODE_CL:
        topIcon = logoSii;
        topIconWidth = 150;
        break;
      case COUNTRY_CODE_MX:
        topIcon = logoSat;
        topIconWidth = 65;
        break;
      default:
        return null;
    }
    return { topIcon, topIconWidth };
  };

  return (
    <BaseDialog
      isOpen={open}
      handleClose={handleCloseDialog}
      description={`Leemos la información de la empresa directamente del ${t(
        t13s.LABEL.TAX_SERVICE_SHORT_NAME
      )} para darte la mejor alternativa de financiamiento y mostrarla en tiempo real en tu plataforma.`}
      subtitle={`Ingresa tu ${t(t13s.LABEL.TAX_SERVICE_CREDENTIAL)}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getIcon()}
    >
      <Grid container direction="column" alignContent="center">
        <ValidatorForm
          onSubmit={handleSubmitCredential}
          className={classes.form}
        >
          <TextValidator
            type="text"
            name="identifier"
            label={t(t13s.LABEL.BUSINESS_IDENTIFIER)}
            disabled
            variant="outlined"
            value={identifier}
            fullWidth
          />

          <TextValidator
            variant="outlined"
            fullWidth
            type="password"
            name="newPassword"
            label={t(t13s.LABEL.TAX_SERVICE_CREDENTIAL)}
            value={password}
            onChange={e => setPassword(e.target.value)}
            validators={['required']}
            errorMessages={['Campo requerido']}
          />

          {!!createTaxUserError && (
            <AlertForm message={t(createTaxUserError)} variant="error" />
          )}

          <LoadingButton
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            fullWidth
            disabled={createTaxUserIsLoading}
            isLoading={createTaxUserIsLoading || false}
          >
            Ingresar
          </LoadingButton>
        </ValidatorForm>
      </Grid>
    </BaseDialog>
  );
};

TaxServiceCredentialsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default TaxServiceCredentialsDialog;
