import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box, Grid, Fade, Tooltip } from '@material-ui/core';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CustomButton from './elements/CustomButton';
import Panel from './elements/Panel';
import { checkTaxServiceCredential } from '../store/actions/taxServiceActions';
import TaxServiceCredentialsDialog from './dialogs/TaxServiceCredentialsDialog';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import UserSectionHeader from './elements/UserSectionHeader';

const useStyles = makeStyles(theme => ({
  containerBody: {
    display: 'grid',
    gridGap: convertSpacingToCss('lg'),
  },
  subtitle: {
    marginTop: 10,
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  chipIcon: {
    fill: theme.palette.success.main,
  },
}));

const UserSat = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showTaxCredentialsDialog, setShowTaxCredentialsDialog] =
    useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  const { taxServiceCredentialCreated, taxStatus = {} } = useSelector(
    state => state.taxServiceState
  );
  const { id: businessId } = useSelector(state => state.businessState.business);
  const { hasCredential = false, isCredentialActive = false } = taxStatus;

  useEffect(() => {
    dispatch(checkTaxServiceCredential(businessId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId]);

  useEffect(() => {
    if (taxServiceCredentialCreated) {
      setShowTaxCredentialsDialog(false);
    }
  }, [taxServiceCredentialCreated]);

  return (
    <Fade in timeout={400}>
      <Grid item>
        {showTaxCredentialsDialog && (
          <TaxServiceCredentialsDialog
            open={showTaxCredentialsDialog}
            handleCloseDialog={() => setShowTaxCredentialsDialog(false)}
          />
        )}

        <UserSectionHeader setHeaderHeight={setHeaderHeight}>
          <Typography variant="h5" color="textPrimary">
            <Box fontWeight="fontWeightBold">
              Sistema de Administración Tributaria
            </Box>
          </Typography>

          <Typography
            variant="h6"
            color="textPrimary"
            className={classes.subtitle}
          >
            <Box fontWeight="fontWeightBold" className={classes.box}>
              Clave consulta
              <Tooltip title="La Clave de Consulta del CIEC te permite acceder a información tributaria, operar con Facturas Electrónicas, y consultar sobre tu situación tributaria, entre otras cosas.">
                <HelpOutlineRoundedIcon color="secondary" fontSize="small" />
              </Tooltip>
            </Box>
          </Typography>

          <Typography
            variant="body1"
            color="textSecondary"
            className={classes.subtitle}
          >
            Recuerda que con tu clave CIEC sólo podemos ver tu accionar
            tributario. No podemos emitir facturas ni generar cambios en tu
            nombre.
          </Typography>
        </UserSectionHeader>

        <Box className={classes.containerBody} marginTop={headerHeight}>
          <Panel
            variant="green-title"
            title="Clave de identificación electrónica confidencial (CIEC)"
            actions={
              <>
                {hasCredential && isCredentialActive ? (
                  <CustomButton variant="success" startIcon={<CheckIcon />}>
                    Clave ingresada
                  </CustomButton>
                ) : (
                  <CustomButton
                    variant="orange-lined"
                    onClick={() => setShowTaxCredentialsDialog(true)}
                  >
                    Ingresa clave CIEC
                  </CustomButton>
                )}
              </>
            }
          />
        </Box>
      </Grid>
    </Fade>
  );
};

export default UserSat;
