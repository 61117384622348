import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import 'moment/locale/es';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import CompleteRegister from './components/CompleteRegister';
import AppLayout from './components/hoc/AppLayout';
import authUser from './components/hoc/authUser';
import noAuthUser from './components/hoc/noAuthUser';
import semiAuthUser from './components/hoc/semiAuthUser';
import HomeDesign from './components/HomeDesign';
import HomeMain from './components/HomeMain';
import HomeStart from './components/HomeStart';
import HomeUserProfile from './components/HomeUserProfile';
// import { setPageViewSegment } from './helpers/trackingSegment';
import Impersonate from './components/Impersonate';
import HomePayrollDetails from './components/HomePayrollDetails';
import Notifier from './components/Notifier';
import ResetPassword from './components/ResetPassword';
import configureStore from './modules/store';
import { theme } from './theme/theme';

const { store, persistor } = configureStore();

const useStyles = makeStyles({
  success: { backgroundColor: `${theme.palette.success.main} !important` },
  error: { backgroundColor: `${theme.palette.error.main} !important` },
  warning: { backgroundColor: `${theme.palette.warning.main} !important` },
  info: { backgroundColor: `${theme.palette.info.main} !important` },
});

const appTheme = createTheme(theme);
const App = () => {
  const classes = useStyles();
  // const history = useHistory();

  // useEffect(() => {
  // history.listen(location => {
  //   const { pathname } = location;
  //   setPageViewSegment(pathname);
  // });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <MuiThemeProvider theme={appTheme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SnackbarProvider
            classes={{
              variantSuccess: classes.success,
              variantError: classes.error,
              variantWarning: classes.warning,
              variantInfo: classes.info,
            }}
            maxSnack={4}
          >
            <AppLayout>
              <>
                <Notifier />
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => {
                      return <Redirect to="/finance/" />;
                    }}
                  />
                  <Route
                    exact
                    path="/start"
                    render={() => {
                      return <Redirect to="/start/login" />;
                    }}
                  />
                  <Route
                    exact
                    path="/completeregister"
                    component={semiAuthUser(CompleteRegister)}
                  />
                  <Route path="/start" component={noAuthUser(HomeStart)} />
                  <Route path="/finance" component={authUser(HomeMain)} />
                  <Route path="/orders" component={authUser(HomeMain)} />
                  <Route path="/payments" component={authUser(HomeMain)} />
                  <Route path="/suppliers" component={authUser(HomeMain)} />
                  <Route path="/xtracker" component={authUser(HomeMain)} />
                  <Route
                    path="/user/profile"
                    component={authUser(HomeUserProfile)}
                  />
                  <Route
                    path="/resetpassword/:hash"
                    component={noAuthUser(ResetPassword)}
                  />
                  <Route
                    path="/impersonate/:token"
                    component={noAuthUser(Impersonate)}
                  />
                  <Route path="/design" component={authUser(HomeDesign)} />
                  <Route
                    path="/payrolls/:payrollId"
                    component={authUser(HomePayrollDetails)}
                  />
                </Switch>
              </>
            </AppLayout>
          </SnackbarProvider>
        </PersistGate>
      </Provider>
    </MuiThemeProvider>
  );
};

export default App;
