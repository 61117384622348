import axios from 'axios';
import {
  FETCH_ORDER_DETAIL_START,
  FETCH_ORDER_DETAIL_SUCCESS,
  FETCH_ORDER_DETAIL_ERROR,
  FETCH_ORDER_INVOICES_START,
  FETCH_ORDER_INVOICES_SUCCESS,
  FETCH_ORDER_INVOICES_ERROR,
  RESET_ORDER_DETAILS,
  FETCH_INVOICE_DOCUMENTS_START,
  FETCH_INVOICE_DOCUMENTS_SUCCESS,
  FETCH_INVOICE_DOCUMENTS_ERROR,
  DOWNLOAD_INVOICE_DOCUMENTS_START,
  DOWNLOAD_INVOICE_DOCUMENTS_SUCCESS,
  DOWNLOAD_INVOICE_DOCUMENTS_ERROR,
} from './types';
import { t13s } from '../../translationKeys';
import { b64toBlob, download } from '../../helpers/fileHandling';
import { enqueueSnackbar } from './notificationActions';

// eslint-disable-next-line import/prefer-default-export
export const fetchOrderDetail = (orderId, businessId) => dispatch => {
  dispatch({ type: FETCH_ORDER_DETAIL_START });
  axios
    .get(`/api/business/${businessId}/order/${orderId}`)
    .then(res => {
      const { OrderDetail } = res.data;

      dispatch({
        type: FETCH_ORDER_DETAIL_SUCCESS,
        payload: { id: orderId, resume: OrderDetail },
      });
    })
    .catch(err => {
      dispatch({ type: FETCH_ORDER_DETAIL_ERROR, payload: err });
    });
};

export const fecthOrderInvoices =
  (orderId, businessId, page, pageSize) => dispatch => {
    dispatch({ type: FETCH_ORDER_INVOICES_START });
    axios
      .get(
        `/api/business/${businessId}/order/${orderId}/invoice?size=${pageSize}&page=${page}`
      )
      .then(res => {
        const { data: invoices, pagination = {} } = res.data;
        dispatch({
          type: FETCH_ORDER_INVOICES_SUCCESS,
          payload: { invoices, pagination },
        });
      })
      .catch(err => {
        dispatch({ type: FETCH_ORDER_INVOICES_ERROR, payload: err });
      });
  };

export const resetOrderDetail = () => dispatch => {
  dispatch({ type: RESET_ORDER_DETAILS });
};

export const getInvoiceDocuments = (businessId, orderId) => dispatch => {
  dispatch({ type: FETCH_INVOICE_DOCUMENTS_START });
  axios
    .get(`/api/business/${businessId}/order/${orderId}/bill`)
    .then(({ data }) => {
      dispatch({
        type: FETCH_INVOICE_DOCUMENTS_SUCCESS,
        payload: { invoiceDocuments: data },
      });
    })
    .catch(err => {
      const errorCode = err?.response?.data?.errorCode;
      dispatch({
        type: FETCH_INVOICE_DOCUMENTS_ERROR,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.FETCH_INVOICE_DOCUMENTS_ERROR,
          options: {
            variant: 'error',
          },
        })
      );
    });
};

export const downloadInvoiceDocuments = (orderId, invoiceId) => dispatch => {
  dispatch({ type: DOWNLOAD_INVOICE_DOCUMENTS_START });
  axios
    .get(`/api/order/${orderId}/bill/${invoiceId}`)
    .then(({ data }) => {
      dispatch({
        type: DOWNLOAD_INVOICE_DOCUMENTS_SUCCESS,
      });
      const { file } = data;
      let blob;
      let extension = 'pdf';
      if (file.includes('base64')) {
        const fileData = file.split(';base64,');
        extension = fileData[0].split(/\//)?.[1];
        blob = b64toBlob(
          fileData[1].replace(/={1,2}$/, ''),
          extension === 'pdf' ? 'application/pdf' : `image/${extension}`
        );
      } else {
        blob = b64toBlob(file, 'application/pdf');
      }
      const name = `${invoiceId}.${extension}`;
      return download(blob, name);
    })
    .catch(err => {
      const errorCode = err?.response?.data?.errorCode;
      dispatch({
        type: DOWNLOAD_INVOICE_DOCUMENTS_ERROR,
        payload: { errorCode },
      });
      dispatch(
        enqueueSnackbar({
          message: errorCode,
          options: {
            variant: 'error',
          },
        })
      );
    });
};
