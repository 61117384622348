import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { Typography, Box, Tooltip } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import PaginationMaterialTable from '../elements/PaginationMaterialTable';
import {
  ORDER_STATUS_GROUP,
  ORDER_STATUS_GROUP_LABEL,
  AVAILABLE_COUNTRIES,
} from '../../helpers/constants';
import { t13s } from '../../translationKeys';
import Chip from '../elements/Chip';
import CountryFormatHelper from '../elements/CountryFormatHelper';

const InvoiceTableOrderDetails = React.memo(props => {
  const { tableData, isLoading, countryId, pagination, updateInvoices } = props;
  const { totalData, page, pageSize } = pagination;
  const { t } = useTranslation();
  const { IN_REVIEW, APPROVED, TRANSFERRED, PAID, REJECTED, RENEGOTIATION } =
    ORDER_STATUS_GROUP;

  const handleChangePage = (selectedPage, pageSize) => {
    updateInvoices(selectedPage, pageSize);
  };

  const getVariant = statusGroup => {
    let variant = '';
    switch (statusGroup) {
      case IN_REVIEW:
        variant = 'yellow';
        break;
      case APPROVED:
        variant = 'blue';
        break;
      case TRANSFERRED:
        variant = 'green';
        break;
      case PAID:
        variant = 'lightblue';
        break;
      case REJECTED:
        variant = 'red';
        break;
      case RENEGOTIATION:
        variant = 'purple';
        break;
      default:
        variant = 'default';
        break;
    }
    return variant;
  };
  const getColumns = () => {
    const columnsArray = [
      {
        title: 'Folio',
        field: 'folio',
        type: 'numeric',
        width: '80px',
        align: 'left',
        render: invoice => (
          <Typography variant="body1" color="textSecondary" component="div">
            <Box fontWeight="fontWeightBold">{invoice.folio}</Box>
          </Typography>
        ),
      },
      {
        title: 'Nombre Pagador',
        field: 'payerName',
        type: 'string',
        align: 'left',
        render: invoice => (
          <Typography variant="body1" color="textPrimary" component="div">
            <Box fontWeight="fontWeightBold">
              {invoice.payerName.toUpperCase()}
            </Box>
          </Typography>
        ),
      },
      {
        title: t(t13s.LABEL.BUSINESS_IDENTIFIER),
        field: 'payerIdentifier',
        type: 'string',
        width: '160px',
        align: 'left',
        sorting: true,
        render: invoice => (
          <Typography
            variant="body1"
            color="textSecondary"
            component="div"
            noWrap
          >
            <Box fontWeight="fontWeightBold">{invoice.payerIdentifier}</Box>
          </Typography>
        ),
      },
      {
        title: 'Fecha de Emisión',
        field: 'issueDate',
        type: 'datetime',
        width: '160px',
        align: 'left',
        render: invoice => (
          <Typography
            variant="body1"
            color="textSecondary"
            component="div"
            noWrap
          >
            <Box fontWeight="fontWeightBold">
              <CountryFormatHelper
                value={invoice.issuedDate}
                variant="longdate"
                countryId={countryId}
              />
            </Box>
          </Typography>
        ),
      },
      {
        title: 'Fecha de vencimiento',
        field: 'expirationDate',
        type: 'datetime',
        width: '160px',
        align: 'left',
        render: invoice => (
          <Typography
            variant="body1"
            color="textSecondary"
            component="div"
            noWrap
          >
            <Box fontWeight="fontWeightBold">
              <CountryFormatHelper
                value={invoice.expirationDate}
                variant="longdate"
                countryId={countryId}
              />
            </Box>
          </Typography>
        ),
      },
      {
        title: 'Monto Total',
        field: 'totalAmount',
        type: 'numeric',
        width: '150px',
        render: invoice => (
          <Box display="inline-flex">
            {invoice.totalAmountCreditNotes ? (
              <Tooltip
                title={
                  <>
                    Monto nota de crédito: $
                    <CountryFormatHelper
                      value={invoice.totalAmountCreditNotes}
                      countryId={countryId}
                      variant="currency"
                    />
                  </>
                }
              >
                <WarningRoundedIcon color="error" fontSize="small" />
              </Tooltip>
            ) : null}
            <Typography variant="body1" color="textSecondary" component="div">
              <Box fontWeight="fontWeightBold">
                <CountryFormatHelper
                  value={invoice.totalAmount}
                  countryId={countryId}
                  variant="currency"
                  useFixedDecimalPrecision={2}
                  dataQa={`order-invoices-table-monto-total-string-${invoice?.tableData?.id}`}
                />
              </Box>
            </Typography>
          </Box>
        ),
      },
      {
        title: 'Estado',
        field: 'statusGroup',
        type: 'string',
        align: 'center',
        width: '160px',
        render: invoice => {
          const variant = getVariant(invoice.statusGroup);
          return (
            <Chip
              variant={variant}
              size="small"
              label={ORDER_STATUS_GROUP_LABEL[invoice.statusGroup]}
              data-qa={`order-invoices-table-estado-chip-${invoice?.tableData?.id}`}
            />
          );
        },
      },
    ];

    return columnsArray;
  };

  return (
    <MaterialTable
      style={{ width: '100%', boxShadow: 'none' }}
      title=""
      columns={getColumns()}
      data={tableData}
      isLoading={isLoading}
      onPageChange={handleChangePage}
      localization={{
        pagination: {
          labelDisplayedRows: '{count} facturas totales',
          labelRowsSelect: 'facturas',
          labelRowsPerPage: 'Facturas por página',
        },
        toolbar: {
          nRowsSelected: count =>
            count > 1
              ? `${count} facturas seleccionadas`
              : '1 factura seleccionada',
          searchTooltip: 'Buscar',
          searchPlaceholder: 'Buscar',
        },
        header: {
          actions: 'Acciones',
        },
        body: {
          emptyDataSourceMessage: 'No hay facturas para mostrar',
          filterRow: {
            filterTooltip: 'Filtro',
          },
        },
      }}
      options={{
        emptyRowsWhenPaging: false,
        showFirstLastPageButtons: false,
        sorting: true,
        pageSize,
        maxBodyHeight: 440,
        pageSizeOptions: [5, 10, 20, 50, 100],
      }}
      components={{
        Pagination: props => (
          <PaginationMaterialTable
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            count={totalData}
            page={page - 1}
          />
        ),
      }}
    />
  );
});

InvoiceTableOrderDetails.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateInvoices: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    totalData: PropTypes.number,
    page: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
};

export default InvoiceTableOrderDetails;
