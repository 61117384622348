import { PURGE } from 'redux-persist';
import {
  LOCAL_PURGE,
  FETCH_STAKEHOLDERS_START,
  FETCH_STAKEHOLDERS_ERROR,
  FETCH_STAKEHOLDERS_SUCCESS,
  EDIT_STAKEHOLDERS_START,
  EDIT_STAKEHOLDERS_ERROR,
  EDIT_STAKEHOLDERS_SUCCESS,
} from '../actions/types';

const initialState = {
  stakeholders: [],
  fetchStakeholdersIsLoading: false,
  fetchStakeholdersError: null,
  updateStakeholdersIsLoading: false,
  updateStakeholdersIsDone: false,
  updateStakeholdersError: null,
};

const fetchStakeholdersStart = state => {
  return {
    ...state,
    fetchStakeholdersIsLoading: true,
    fetchStakeholdersError: null,
  };
};

const fetchStakeholdersSuccess = (state, payload) => {
  return {
    ...state,
    fetchStakeholdersIsLoading: false,
    fetchStakeholdersError: null,
    stakeholders: payload.stakeholders,
  };
};

const fetchStakeholdersError = (state, payload) => {
  return {
    ...state,
    fetchStakeholdersIsLoading: false,
    fetchStakeholdersError: payload.errorCode,
  };
};

const updateStakeholdersStart = state => {
  return {
    ...state,
    updateStakeholdersIsLoading: true,
    updateStakeholdersIsDone: false,
    updateStakeholdersError: null,
  };
};

const updateStakeholdersSuccess = (state, payload) => {
  const { stakeholders } = state;
  const { updatedStakeholder } = payload;
  const updatedStakeholdersList = stakeholders.map(s =>
    s.id === updatedStakeholder.id ? updatedStakeholder : s
  );
  return {
    ...state,
    stakeholders: [...updatedStakeholdersList],
    updateStakeholdersIsLoading: false,
    updateStakeholdersIsDone: true,
  };
};

const updateStakeholdersFailure = (state, payload) => {
  return {
    ...state,
    editStakeholderIsLoading: false,
    updateStakeholdersIsLoading: false,
    updateStakeholdersError: payload,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_STAKEHOLDERS_START:
      return fetchStakeholdersStart(state);
    case FETCH_STAKEHOLDERS_SUCCESS:
      return fetchStakeholdersSuccess(state, payload);
    case FETCH_STAKEHOLDERS_ERROR:
      return fetchStakeholdersError(state, payload);
    case EDIT_STAKEHOLDERS_START:
      return updateStakeholdersStart(state);
    case EDIT_STAKEHOLDERS_SUCCESS:
      return updateStakeholdersSuccess(state, payload);
    case EDIT_STAKEHOLDERS_ERROR:
      return updateStakeholdersFailure(state, payload);
    case LOCAL_PURGE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
