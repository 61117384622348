import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ErrorCircleIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
  Box,
} from '@material-ui/core';
import { FLOWS_ORDER } from '../../helpers/constants';
import {
  ORDER_STEP_COMPLETED,
  ORDER_STEP_WARNING,
} from '../../theme/otherColors';

const connectorStyles = makeStyles({
  alternativeLabel: {
    left: 'calc(-50% + 8px)',
    right: 'calc(50% + 8px)',
    top: 4,
  },
  active: {
    '& $line': {
      borderColor: '#F0EDF5',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#F0EDF5',
    },
  },
  line: {
    borderTopWidth: 24,
    borderRadius: 0,
    borderColor: '#F0EDF5',
  },
});

const QontoConnector = React.forwardRef((props, ref) => {
  const classes = connectorStyles();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StepConnector {...props} ref={ref} classes={{ ...classes }} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiPaper-root': {
      background: 'transparent',
    },
  },
  step: {
    padding: '2px 2px',
    '& $completed': {
      zIndex: 20,
      borderRight: '1px solid white',
      borderLeft: '1px solid white',
      borderRadius: '14px',
      background: 'white',
      padding: '2px 2px',
      color: ORDER_STEP_COMPLETED,
    },
    '& $active': {
      zIndex: 20,
      borderRight: '1px solid white',
      borderLeft: '1px solid white',
      borderRadius: '14px',
      background: 'white',
      padding: '2px 2px',
      color: ORDER_STEP_COMPLETED,
    },
    '& $warning': {
      color: ORDER_STEP_WARNING,
      zIndex: 20,
      borderRight: '1px solid white',
      borderLeft: '1px solid white',
      borderRadius: '14px',
      background: 'white',
      padding: '2px 2px',
    },
    '& $primary': {
      color: theme.palette.primary.main,
      zIndex: 20,
      borderRight: '1px solid white',
      borderLeft: '1px solid white',
      borderRadius: '14px',
      background: 'white',
      padding: '2px 2px',
    },
    '& $error': {
      color: theme.palette.error.main,
      zIndex: 20,
      borderRight: '1px solid white',
      borderLeft: '1px solid white',
      borderRadius: '14px',
      background: 'white',
      padding: '2px 2px',
    },
    '& $disabled': {
      color: 'white',
      background: 'white',
      zIndex: 20,
      borderRight: '1px solid white',
      borderLeft: '1px solid white',
      borderRadius: '14px',
      padding: '3px 2px 4px 2px',
    },
    '& .MuiStepLabel-label': {
      background: 'transparent',
      border: 0,
    },
  },
  disabledIcon: {
    fontSize: '13px',
    fontWeight: 'bold',
    fontFamily: 'Muli',
    background: theme.palette.grey[400],
    padding: '0px 6px',
    border: '1px solid white',
    borderRadius: '20px',
  },
  alternativeLabel: {},
  active: {}, // needed so that the &$active tag works
  completed: {},
  disabled: {},
  error: {},
  warning: {},
  primary: {},
}));

const getStepIcon = (props, variant, classes) => {
  const { icon: defaultIcon } = props;

  let icon;
  switch (variant) {
    case 'error':
    case 'warning':
      icon = <ErrorCircleIcon className={classes[variant]} />;
      break;
    case 'completed':
    case 'primary':
      icon = <CheckCircleIcon className={classes[variant]} />;
      break;
    default:
      icon = (
        <span className={classes.disabled}>
          <div className={classes.disabledIcon}>{defaultIcon}</div>
        </span>
      );
      break;
  }
  return icon;
};

const TransferStatusSteps = ({ order }) => {
  const classes = useStyles();
  const flowKey = order.pymeStatus;
  const flowSteps = FLOWS_ORDER[flowKey];
  const activeStep = flowSteps.reduce((sum, step) => sum + step.active, -1);

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<QontoConnector />}
        classes={{
          root: classes.step,
        }}
      >
        {flowSteps.map(step => {
          const { label, variant } = step;

          return (
            <Step key={label}>
              <StepLabel
                classes={{
                  root: classes.step,
                  completed: classes.active,
                  active: classes[variant],
                  error: classes.error,
                }}
                StepIconComponent={props =>
                  getStepIcon(props, variant, classes)
                }
              >
                <Typography variant="body2" component="div">
                  <Box fontWeight="fontWeightBold">{label}</Box>
                </Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

TransferStatusSteps.propTypes = {
  order: PropTypes.objectOf(Object).isRequired,
};

export default TransferStatusSteps;
