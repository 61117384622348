// eslint-disable-next-line import/prefer-default-export
export const getEmailMask = email => {
  const maskedEmail = email.replace(/([^@.])/g, '*').split('');
  let previous = '';
  for (let i = 0; i < maskedEmail.length; i++) {
    if (i <= 1 || previous === '.' || previous === '@') {
      maskedEmail[i] = email[i];
    }
    previous = email[i];
  }
  return maskedEmail.join('');
};
