import axios from 'axios';
import {
  CONTACT_DETAIL_CLEANUP,
  CONTACT_DETAIL_RESET,
  CREATE_CONTACT_DETAIL_FAILURE,
  CREATE_CONTACT_DETAIL_START,
  CREATE_CONTACT_DETAIL_SUCCESS,
  DELETE_CONTACT_DETAIL_FAILURE,
  DELETE_CONTACT_DETAIL_START,
  DELETE_CONTACT_DETAIL_SUCCESS,
  FETCH_CONTACT_DETAIL_FAILURE,
  FETCH_CONTACT_DETAIL_START,
  FETCH_CONTACT_DETAIL_SUCCESS,
  UPDATE_CONTACT_DETAIL_FAILURE,
  UPDATE_CONTACT_DETAIL_START,
  UPDATE_CONTACT_DETAIL_SUCCESS,
} from './types';
import { t13s } from '../../translationKeys';

import { enqueueSnackbar } from './notificationActions';

const parseContactDetail = contactDetail => {
  const { contactableType } = contactDetail;
  return { ...contactDetail, contactableType: contactableType.toLowerCase() };
};

export const fetchContactDetails =
  (contactableId, contactableType) => dispatch => {
    dispatch({
      type: FETCH_CONTACT_DETAIL_START,
      payload: { contactableType, contactableId },
    });
    axios
      .get(`/api/${contactableType}/${contactableId}/contactdetail`)
      .then(res => {
        dispatch({
          type: FETCH_CONTACT_DETAIL_SUCCESS,
          payload: {
            contactDetails: res.data.map(contactDetail =>
              parseContactDetail(contactDetail)
            ),
            contactableType,
            contactableId,
          },
        });
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: FETCH_CONTACT_DETAIL_FAILURE,
          payload: { errorCode, contactableType, contactableId },
        });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.FETCH_CONTACT_DETAIL_FAILURE,
            options: {
              variant: 'success',
            },
          })
        );
      });
  };

export const createContactDetail =
  (contactableId, contactableType, contactDetailData) => dispatch => {
    dispatch({
      type: CREATE_CONTACT_DETAIL_START,
      payload: { contactableType, contactableId },
    });
    axios
      .post(`/api/${contactableType}/${contactableId}/contactdetail`, {
        ...contactDetailData,
      })
      .then(res => {
        dispatch({
          type: CREATE_CONTACT_DETAIL_SUCCESS,
          payload: parseContactDetail(res.data),
        });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.CREATE_CONTACT_DETAIL_SUCCESS,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: CREATE_CONTACT_DETAIL_FAILURE,
          payload: { errorCode, contactableType, contactableId },
        });
      });
  };

export const updateContactDetail =
  (contactableId, contactableType, contactDetailId, contactDetailData) =>
  dispatch => {
    dispatch({
      type: UPDATE_CONTACT_DETAIL_START,
      payload: { contactableType, contactableId },
    });
    axios
      .put(
        `/api/${contactableType}/${contactableId}/contactdetail/${contactDetailId}`,
        contactDetailData
      )
      .then(res => {
        dispatch({
          type: UPDATE_CONTACT_DETAIL_SUCCESS,
          payload: parseContactDetail(res.data),
        });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.UPDATE_CONTACT_DETAIL_SUCCESS,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: UPDATE_CONTACT_DETAIL_FAILURE,
          payload: { errorCode, contactableType, contactableId },
        });
      });
  };

export const deleteContactDetail =
  (contactableId, contactableType, contactDetailId) => dispatch => {
    dispatch({
      type: DELETE_CONTACT_DETAIL_START,
      payload: { contactableType, contactableId },
    });
    axios
      .delete(
        `/api/${contactableType}/${contactableId}/contactdetail/${contactDetailId}`
      )
      .then(() => {
        dispatch({
          type: DELETE_CONTACT_DETAIL_SUCCESS,
          payload: { contactDetailId, contactableType },
        });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.DELETE_CONTACT_DETAIL_SUCCESS,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: DELETE_CONTACT_DETAIL_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const resetContactDetail = contactableType => dispatch => {
  dispatch({
    type: CONTACT_DETAIL_RESET,
    payload: { contactableType },
  });
};

export const cleanupContactDetail = contactableType => dispatch => {
  dispatch({
    type: CONTACT_DETAIL_CLEANUP,
    payload: { contactableType },
  });
};
