// eslint-disable-next-line import/prefer-default-export
export const MX = {
  orderCreation: {
    totalTableTooltip: true,
    resumeInteresRateTooltip: true,
  },
  legal: {
    stakeholdersConjugal: false,
  },
  invoicesTable: {
    totalAmountTooltip: true,
  },
  bankAccountActions: {
    showCurrencyInput: true,
  },
  profileSidebar: [
    {
      key: 'profile',
      label: 'Perfil de Usuario',
      path: '/user/profile',
    },
    {
      key: 'legal',
      label: 'Información Legal',
      path: '/user/profile/legal',
    },
    {
      key: 'bankAccounts',
      label: 'Cuentas Bancarias',
      path: '/user/profile/bankaccounts',
    },
    {
      key: 'sat',
      label: 'SAT',
      path: '/user/profile/sat',
    },
  ],
};
