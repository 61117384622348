import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  IconButton,
} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import { convertSpacingToCss } from '../../helpers/stylesHelpers';

const useStyles = makeStyles({
  rootDialog: {
    borderRadius: 17,
    width: props => (props.size === 'lg' ? 800 : 340),
    padding: convertSpacingToCss('50px xl xl xl'),
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  imgContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: convertSpacingToCss('lg'),
  },
  topIcon: {
    width: props => props.topIconWidth || 140,
  },
  dialogTitle: {
    padding: 0,
  },
  item: {
    marginBottom: convertSpacingToCss('lg'),
  },
  content: {
    padding: 0,
    overflow: 'unset',
  },
});

const BaseDialog = props => {
  const classes = useStyles(props);
  const {
    isOpen,
    handleClose,
    title,
    description,
    subtitle,
    children,
    topIcon,
    topIconWidth,
    hideCloseButton,
    size,
    paperProps,
  } = props;

  const closeDialogHandler = () => {
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      onClose={closeDialogHandler}
      classes={{ paper: classes.rootDialog }}
      PaperProps={{ ...paperProps }}
    >
      <DialogTitle classes={{ root: classes.dialogTitle }} disableTypography>
        {!hideCloseButton && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeDialogHandler}
            data-qa="dialog-close-button-action"
          >
            <CloseIcon />
          </IconButton>
        )}
        {topIcon && (
          <div className={classes.imgContainer}>
            <img
              src={topIcon}
              width={topIconWidth}
              className={classes.topIcon}
              alt="Top"
            />
          </div>
        )}
        {title && (
          <Typography
            variant="h5"
            color="textPrimary"
            align={size === 'lg' ? 'left' : 'center'}
            component="div"
            className={classes.item}
          >
            <Box fontWeight="fontWeightBold">{title}</Box>
          </Typography>
        )}
        {description && (
          <Typography
            variant="body1"
            color="textPrimary"
            align={size === 'lg' ? 'left' : 'center'}
            component="div"
            className={classes.item}
          >
            {description}
          </Typography>
        )}
        {subtitle && (
          <Typography
            variant="h6"
            color="textPrimary"
            align={size === 'lg' ? 'left' : 'center'}
            component="div"
            className={classes.item}
          >
            <Box fontWeight="fontWeightBold">{subtitle}</Box>
          </Typography>
        )}
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

BaseDialog.defaultProps = {
  topIcon: null,
  topIconWidth: null,
  hideCloseButton: false,
  size: 'md',
  title: null,
  subtitle: null,
  description: null,
  paperProps: {},
};

BaseDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.element.isRequired,
  topIcon: PropTypes.string,
  topIconWidth: PropTypes.number,
  hideCloseButton: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  size: PropTypes.oneOf(['md', 'lg']),
  paperProps: PropTypes.shape({}),
};

export default BaseDialog;
