import React, { useEffect } from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import PaymentsStats from './elements/PaymentsStats';
import PaymentsTabs from './elements/PaymentsTabs';
import PaymentsInboxTable from './PaymentsInboxTable';
import PaymentsPaidTable from './PaymentsPaidTable';
import PaymentsArchivedTable from './PaymentsArchivedTable';
import { PAYMENTS_TABS } from '../helpers/constants';
import PaymentsScheduledCards from './PaymentsScheduledCards';
import OnboardPageFillProfile from './OnboardPageFillProfile';
import Loader from './elements/Loader';
import { checkTaxServiceCredential } from '../store/actions/taxServiceActions';
import { fetchOnboard } from '../store/actions/onboardActions';
import OnboardPageScheduleInvoices from './OnboardPageScheduleInvoices';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    position: 'relative',
    alignContent: 'flex-start',
    height: '100%',
    minHeight: 'calc(100vh - 56px)',
  },
  invoicesWrapper: {
    position: 'relative',
    padding: '0 20px 0 20px',
    width: '100%',
    height: '100%',
    alignContent: 'flex-start',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
}));

const INVOICES_TABLES = {
  [PAYMENTS_TABS.INBOX]: (countryId, businessId) => (
    <PaymentsInboxTable countryId={countryId} businessId={businessId} />
  ),
  [PAYMENTS_TABS.SCHEDULED]: (countryId, businessId) => (
    <PaymentsScheduledCards countryId={countryId} businessId={businessId} />
  ),
  [PAYMENTS_TABS.PAID]: (countryId, businessId) => (
    <PaymentsPaidTable countryId={countryId} businessId={businessId} />
  ),
  [PAYMENTS_TABS.ARCHIVED]: (countryId, businessId) => (
    <PaymentsArchivedTable countryId={countryId} businessId={businessId} />
  ),
};

const HomePayments = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { countryId, id: businessId } = useSelector(
    state => state.businessState.business
  );
  const { hasCredential, isCredentialActive } = useSelector(
    state => state.taxServiceState.taxStatus
  );
  const { checkTaxServiceCredentialIsLoading } = useSelector(
    state => state.taxServiceState
  );
  const { isOnboarded, fetchOnboardLoading } = useSelector(
    state => state.onboardState
  );
  const { activeTab } = useSelector(state => state.paymentsState);

  useEffect(() => {
    if (countryId) {
      dispatch(checkTaxServiceCredential(businessId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isOnboarded && businessId) {
      dispatch(fetchOnboard(businessId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnboarded, businessId]);

  if (hasCredential === false || isCredentialActive === false) {
    return <OnboardPageFillProfile />;
  }

  if (checkTaxServiceCredentialIsLoading || fetchOnboardLoading) {
    return (
      <Grid container className={classes.root}>
        <Grid item>
          <Loader />
        </Grid>
      </Grid>
    );
  }

  if (!isOnboarded) {
    return <OnboardPageScheduleInvoices />;
  }

  return (
    <Grid container className={classes.root}>
      <PaymentsTabs activeTab={activeTab} />
      <PaymentsStats />
      <Grid container item className={classes.invoicesWrapper}>
        {INVOICES_TABLES[activeTab](countryId, businessId)}
      </Grid>
    </Grid>
  );
};

export default HomePayments;
