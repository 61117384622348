import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, CircularProgress, Box, Typography } from '@material-ui/core';
import UploadFileDialog from './dialogs/UploadFileDialog';
import {
  uploadDocument,
  downloadDocument,
} from '../store/actions/documentsActions';
import { downloadInvoiceDocuments } from '../store/actions/orderActions';
import { toBase64 } from '../helpers/fileHandling';
import BaseDialog from './dialogs/BaseDialog';
import HorizontalSeparator from './elements/HorizontalSeparator';
import BusinessDocumentItem from './elements/BusinessDocumentItem';
import InvoiceDocumentItem from './elements/InvoiceDocumentItem';

const CUSTOM_DOCUMENTS = [
  {
    documentableType: 'business',
    title: 'Comprobante de domicilio de la empresa',
    customDescription: (
      <Typography variant="body2" color="textSecondary">
        A nombre de la empresa, no mayor a 3 meses y domicilio completo.
        <ul style={{ marginTop: 0, paddingLeft: 20 }}>
          <li>
            <Box fontWeight="bold" display="inline ">
              Comprobantes aceptados:{' '}
            </Box>
            Recibo de luz CFE, recibo de telefonía celular, recibo de Gas
            Natural, teléfono fijo o internet (Izzi, Axtel, Telmex, Totalplay,
            etc.), boleta predial y recibo de agua.
          </li>
          <li>
            <Box fontWeight="bold" display="block">
              Documentos NO aceptados:{' '}
            </Box>
            Estados de cuenta bancarios, documentos no legibles y facturas.
          </li>
        </ul>
      </Typography>
    ),
  },
  {
    documentableType: 'stakeholder',
    title: 'Comprobante Domicilio',
    customDescription: (
      <Typography variant="body2" color="textSecondary">
        A nombre del representante legal, no mayor a 3 meses y domicilio
        completo.
        <ul style={{ marginTop: 0, paddingLeft: 20 }}>
          <li>
            <Box fontWeight="bold" display="inline ">
              Comprobantes aceptados:{' '}
            </Box>
            Recibo de luz CFE, recibo de telefonía celular, recibo de Gas
            Natural, teléfono fijo o internet (Izzi, Axtel, Telmex, Totalplay,
            etc.), boleta predial y recibo de agua.
          </li>
          <li>
            <Box fontWeight="bold" display="block">
              Documentos NO aceptados:{' '}
            </Box>
            Estados de cuenta bancarios, documentos no legibles y facturas.
          </li>
        </ul>
      </Typography>
    ),
  },
];

const UserDocumentSection = ({
  documents,
  invoiceDocuments,
  documentsAreLoading,
  documentableType,
  variant,
}) => {
  const dispatch = useDispatch();
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const {
    documentWasUpload,
    uploadDocumentIsLoading,
    uploadDocumentError,
    documentWasDownload,
  } = useSelector(state => state.documentsState);
  const { invoiceDocumentWasDownloaded } = useSelector(
    state => state.orderState
  );
  const { id: orderId } = useSelector(state => state.orderState);

  useEffect(() => {
    setOpenUploadDialog(false);
  }, [documentWasUpload]);

  useEffect(() => {
    if (documentWasDownload || invoiceDocumentWasDownloaded) {
      setOpenDownloadDialog(false);
    }
  }, [documentWasDownload, invoiceDocumentWasDownloaded]);

  const handleUploadClick = document => {
    setSelectedDocument(document);
    setOpenUploadDialog(true);
  };

  const handleUpload = async (document, files) => {
    const fileBase64 = await toBase64(files[0].file);
    dispatch(uploadDocument(document, fileBase64, documentableType));
  };

  const handleDownloadClick = document => {
    setSelectedDocument(document);
    setOpenDownloadDialog(true);
    dispatch(downloadDocument(document, documentableType));
  };

  const handleDownloadInvoiceDocument = document => {
    setSelectedDocument(document);
    setOpenDownloadDialog(true);
    dispatch(downloadInvoiceDocuments(orderId, document.id));
  };

  const getCustomDescriptionComponent = document => {
    const customDocument = CUSTOM_DOCUMENTS.find(
      customDocument =>
        document.documentableType === customDocument.documentableType &&
        document.title === customDocument.title
    );

    return customDocument?.customDescription;
  };

  return (
    <>
      {openUploadDialog && (
        <UploadFileDialog
          title={`Cargar archivo: ${selectedDocument?.title}`}
          accept=".pdf,image/jpeg,image/gif,image/png"
          open={openUploadDialog}
          handleCloseDialog={() => {
            setOpenUploadDialog(false);
          }}
          handleSubmit={files => handleUpload(selectedDocument, files)}
          isLoading={uploadDocumentIsLoading}
          error={uploadDocumentError}
        />
      )}

      {openDownloadDialog && (
        <BaseDialog
          title={`Descargando archivo: ${
            selectedDocument?.title || selectedDocument?.type === 'interest'
              ? 'Factura Interés'
              : 'Factura Asesoría'
          }`}
          isOpen={openDownloadDialog}
          handleClose={() => {
            setOpenDownloadDialog(false);
          }}
          hideCloseButton
        >
          <Grid item xs={12} align="center">
            <CircularProgress size={60} color="primary" />
          </Grid>
        </BaseDialog>
      )}
      {documentsAreLoading ? (
        <Box display="flex" justifyContent="center" pt={2} pb={2}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        <Grid item xs={12}>
          {documents
            .map(document => (
              <BusinessDocumentItem
                key={document.id}
                document={document}
                handleUploadClick={() => handleUploadClick(document)}
                handleDownloadClick={() => handleDownloadClick(document)}
                variant={variant}
                descriptionComponent={getCustomDescriptionComponent(document)}
              />
            ))
            .reduce((accu, elem) => {
              return accu === null
                ? [elem]
                : [
                    ...accu,
                    <HorizontalSeparator key={`key${accu.length}`} />,
                    elem,
                  ];
            }, null)}
          {invoiceDocuments.length ? <HorizontalSeparator /> : null}
          {invoiceDocuments
            .map(document => (
              <InvoiceDocumentItem
                key={document.id}
                document={document}
                handleDownloadClick={() =>
                  handleDownloadInvoiceDocument(document)
                }
              />
            ))
            .reduce((accu, elem) => {
              return accu === null
                ? [elem]
                : [
                    ...accu,
                    <HorizontalSeparator key={`key${accu.length}`} />,
                    elem,
                  ];
            }, null)}
        </Grid>
      )}
    </>
  );
};

UserDocumentSection.defaultProps = {
  documents: [],
  invoiceDocuments: [],
  variant: 'default',
};

UserDocumentSection.propTypes = {
  documentableType: PropTypes.oneOf(['business', 'stakeholder', 'order'])
    .isRequired,
  variant: PropTypes.oneOf('small', 'default'),
  documentsAreLoading: PropTypes.bool.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      approved: PropTypes.bool.isRequired,
      action: PropTypes.string.isRequired,
      signedDocument: PropTypes.string,
      document: PropTypes.string,
    })
  ),
  invoiceDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      folio: PropTypes.string.isRequired,
      pdf: PropTypes.string.isRequired,
      xml: PropTypes.string.isRequired,
    })
  ),
};

export default UserDocumentSection;
