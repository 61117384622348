import React, { useEffect, useContext } from 'react';
import {
  Typography,
  Grid,
  Box,
  makeStyles,
  alpha,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { StepperContext } from './StepperFlow';
import BankTransferDetailsTable from './tables/BankTransferDetailsTable';
import InfoIcon from './icons/InfoIcon';
import { AVAILABLE_COUNTRIES } from '../helpers/constants';
import StepperTitle from './elements/StepperTitle';
import StepperSubtitle from './elements/StepperSubtitle';
import CustomButton from './elements/CustomButton';
import { payPayroll } from '../store/actions/payrollActions';
import BackIcon from './icons/BackIcon';

const FLOW_TYPE = 'bankTransfer';

const useStyles = makeStyles(theme => ({
  boxAlert: {
    flexFlow: 'row nowrap',
    marginBottom: 80,
  },
  boxAlertLeft: {
    width: 48,
    padding: '0 38px 0 38px',
    boxSizing: 'content-box',
  },
  boxAlertWrapper: {
    width: 48,
    height: 48,
    background: alpha(theme.palette.info.light, 0.13),
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoIcon: {
    color: theme.palette.info.light,
    width: 5,
    height: 16,
  },
  rootButton: {
    marginTop: 28,
    marginBottom: 28,
  },
  backButton: {
    marginRight: 22,
  },
  goBack: {
    width: 48,
    height: 48,
    padding: 0,
    margin: '0 0 15px 0',
  },
}));

const PayInvoicesBankTransferStep1 = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { updateState, goNext } = useContext(StepperContext);
  const { payingPayroll, payrollPaid } = useSelector(
    state => state.payrollsState
  );
  const {
    invoicesTotalAmount,
    countryId,
    goBackNested,
    businessId,
    payrollId,
  } = props;

  const handleAlreadyPaid = async () => {
    updateState({
      paid: true,
    });
    await dispatch(payPayroll(businessId, [payrollId]));
    goNext();
  };

  const handlePayLater = async () => {
    updateState({
      paid: false,
    });
    await dispatch(payPayroll(businessId, [payrollId]));
    goNext();
  };

  useEffect(() => {
    updateState({
      flowType: FLOW_TYPE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item direction="row" wrap="nowrap" spacing={2}>
      <Grid item>
        <IconButton
          disableRipple
          type="button"
          onClick={goBackNested}
          className={classes.goBack}
        >
          <BackIcon />
        </IconButton>
      </Grid>
      <Grid container item direction="column">
        <StepperTitle>Realiza la transferencia bancaria</StepperTitle>
        <StepperSubtitle>
          Transfiere el monto total de la operación a la siguiente cuenta
          bancaria de Xepelin.
        </StepperSubtitle>

        <Grid container item className={classes.boxAlert}>
          <Grid
            container
            item
            className={classes.boxAlertLeft}
            justifyContent="center"
            alignContent="center"
          >
            <Box className={classes.boxAlertWrapper}>
              <InfoIcon className={classes.infoIcon} />
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textPrimary" component="div">
              <Box fontWeight="fontWeightBold">
                No olvides guardar el comprobante de pago de esta operación
              </Box>
            </Typography>
            <Typography variant="subtitle1" component="p">
              En el siguiente paso necesitarás de este comprobante para
              continuar con el proceso.
            </Typography>
          </Grid>
        </Grid>

        <BankTransferDetailsTable
          countryId={countryId}
          invoicesTotalAmount={invoicesTotalAmount}
        />

        <Grid container className={classes.rootButton}>
          {payingPayroll || payrollPaid ? (
            <Grid item justifyContent="flex-start">
              <CircularProgress size={24} color="primary" />
            </Grid>
          ) : (
            <>
              <Grid item>
                <CustomButton
                  variant="simple-gray"
                  color="secondary"
                  size="large"
                  onClick={handlePayLater}
                  className={classes.backButton}
                >
                  Pagar después
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton
                  variant="all-orange"
                  color="secondary"
                  size="large"
                  onClick={handleAlreadyPaid}
                >
                  Ya pagué
                </CustomButton>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

PayInvoicesBankTransferStep1.propTypes = {
  businessId: PropTypes.number.isRequired,
  payrollId: PropTypes.number.isRequired,
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  goBackNested: PropTypes.func.isRequired,
  invoicesTotalAmount: PropTypes.number.isRequired,
};

export default PayInvoicesBankTransferStep1;
