import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Fade,
  Box,
  Grid,
  Typography,
  Tooltip,
  Button,
  Container,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { convertSpacingToCss } from '../helpers/stylesHelpers';
import Panel from './elements/Panel';
import {
  fetchBusinessBankAccounts,
  createBusinessBankAccount,
  updateBusinessBankAccount,
  setDefaultBusinessBankAccount,
  deleteBusinessBankAccount,
} from '../store/actions/businessBankAccountsActions';
import BankAccountItem from './BankAccountItem';
import BankAccountDialog from './dialogs/BankAccountDialog';
import ConfirmDialog from './dialogs/ConfirmDialog';
import { t13s } from '../translationKeys';
import {
  COUNTRY_CODE_MX,
  TRANSFORMED_ACCOUNT_TYPES,
} from '../helpers/constants';
import { enqueueSnackbar } from '../store/actions/notificationActions';
import { SEPARATOR } from '../theme/otherColors';
import UserSectionHeader from './elements/UserSectionHeader';

const useStyles = makeStyles({
  containerBody: {
    display: 'grid',
    gridGap: convertSpacingToCss('lg'),
  },
  subtitle: {
    marginTop: convertSpacingToCss('sm'),
  },
  separatorBottom: {
    height: 1,
    width: '100%',
    backgroundColor: SEPARATOR,
  },
  emptyContainer: {
    paddingLeft: 30,
  },
});

const UserBankAccounts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [selectedBankAccountId, setSelectedBankAccountId] = useState(null);
  const [showBankAccountDialog, setShowBankAccountDialog] = useState(false);
  const [showDeleteAccountDialog, setShowDeleteAccountDialog] = useState(false);
  const [showDefaultAccountDialog, setShowDefaultAccountDialog] =
    useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  const { id: businessId, countryId } = useSelector(
    state => state.businessState.business
  );

  const { bankAccounts, setDefaultAccountError, deleteAccountError } =
    useSelector(state => state.businessBankAccountsState);

  useEffect(() => {
    if (businessId) {
      dispatch(fetchBusinessBankAccounts(businessId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseBankAccountDialog = () => {
    setSelectedBankAccount(null);
    setShowBankAccountDialog(false);
  };

  const handleShowEditDialog = bankAccountId => {
    const selectedBankAccount = bankAccounts.find(
      bankAccount => bankAccount.id === bankAccountId
    );
    setSelectedBankAccount(selectedBankAccount);
    setShowBankAccountDialog(true);
  };

  const handleSubmitBankAccount = bankAccountData => {
    const bankAccountObject = {
      ...bankAccountData,
    };

    if (bankAccountObject.id) {
      const {
        Bank,
        id,
        source,
        sourceId,
        createdAt,
        updatedAt,
        ...restOfBankAccount
      } = bankAccountObject;
      delete bankAccountObject.id;
      dispatch(updateBusinessBankAccount(businessId, id, restOfBankAccount));
    } else {
      dispatch(createBusinessBankAccount(businessId, bankAccountObject));
    }
  };

  const handleShowDeleteAccountDialog = bankAccountId => {
    setSelectedBankAccountId(bankAccountId);
    setShowDeleteAccountDialog(true);
  };

  const handleShowDefaultAccountDialog = bankAccountId => {
    setSelectedBankAccountId(bankAccountId);
    setShowDefaultAccountDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setShowDeleteAccountDialog(false);
    setShowDefaultAccountDialog(false);
    setSelectedBankAccountId(null);
  };

  const handleDeleteBankAccount = () => {
    dispatch(deleteBusinessBankAccount(businessId, selectedBankAccountId));
    if (!deleteAccountError) handleCloseConfirmDialog();
  };

  const handleSetDefaultAccount = () => {
    dispatch(setDefaultBusinessBankAccount(businessId, selectedBankAccountId));
    if (!setDefaultAccountError) handleCloseConfirmDialog();
  };

  const handleCopyData = bankAccountId => {
    const bankAccountData = bankAccounts.find(
      account => account.id === bankAccountId
    );

    if (bankAccountData) {
      const dataString =
        countryId === COUNTRY_CODE_MX
          ? bankAccountData.accountNumber
          : `
    ${bankAccountData.accountHolder}
    ${t(t13s.LABEL.BUSINESS_IDENTIFIER)}: ${bankAccountData.sourceIdentifier}
    ${bankAccountData.Bank.name}
    ${TRANSFORMED_ACCOUNT_TYPES[bankAccountData.accountType]}
    N° de cuenta: ${bankAccountData.accountNumber}
    ${bankAccountData.accountEmail}
    ${bankAccountData.alias ? `Alias: ${bankAccountData.alias}` : 'Sin alias'}
    `;
      copy(dataString, { format: 'text/plain' });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.COPY_DATA_TO_CLIPBOARD_SUCCESS,
          options: {
            variant: 'success',
          },
        })
      );
    } else {
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.COPY_DATA_TO_CLIPBOARD_FAILURE,
          options: {
            variant: 'error',
          },
        })
      );
    }
  };

  return (
    <Fade in timeout={400}>
      <Grid item>
        {showDeleteAccountDialog && (
          <ConfirmDialog
            isOpen={showDeleteAccountDialog}
            handleClose={() => setShowDeleteAccountDialog(false)}
            title="Eliminar cuenta bancaria"
            message="¿Seguro deseas eliminar la cuenta bancaria? La podrás agregar nuevamente si deseas."
            buttonLabel="Eliminar cuenta"
            buttonOnAccept={handleDeleteBankAccount}
          />
        )}
        {showDefaultAccountDialog && (
          <ConfirmDialog
            isOpen={showDefaultAccountDialog}
            handleClose={handleCloseConfirmDialog}
            title="Cambiar cuenta predeterminada"
            message="Si cambias tu cuenta predeterminada ahora, será la que usaremos para transferirte tus futuras operaciones. Necesitamos que confirmes este cambio."
            buttonLabel="Cambiar cuenta"
            buttonOnAccept={handleSetDefaultAccount}
          />
        )}
        {showBankAccountDialog && (
          <BankAccountDialog
            open={showBankAccountDialog}
            onClose={handleCloseBankAccountDialog}
            onSubmit={handleSubmitBankAccount}
            isRegister={!selectedBankAccount}
            bankAccount={selectedBankAccount}
          />
        )}
        <UserSectionHeader setHeaderHeight={setHeaderHeight}>
          <Typography variant="h5" color="textPrimary" component="div">
            <Box fontWeight="fontWeightBold">Cuentas Bancarias</Box>
          </Typography>

          <Typography
            variant="body1"
            color="textSecondary"
            className={classes.subtitle}
          >
            Encuentra aquí la información de tu billetera digital y cuentas
            bancarias. Recuerda que podrás abonarle dinero a tu billetera y
            luego usarla para hacer pagos donde quieras. Las cuentas bancarias
            tienen que ir asociadas al RFC de tu empresa para que podamos
            realizar transferencias a ellas.
          </Typography>
        </UserSectionHeader>

        <Box className={classes.containerBody} marginTop={headerHeight}>
          <Panel
            title="Cuenta Xepelin"
            variant="green-title"
            titleAction={
              <Tooltip title="A todos nuestros clientes les generamos una billetera digital para guardar su dinero. La cuenta está a su nombre pero administrada por nosotros.">
                <HelpOutlineRoundedIcon color="secondary" fontSize="small" />
              </Tooltip>
            }
            contentPadding="zero zero xl zero"
          >
            {bankAccounts?.length ? (
              bankAccounts
                .filter(bankAccount => bankAccount.isXepelin)
                .map(bankAccount => (
                  <BankAccountItem
                    key={`item-${bankAccount.id}`}
                    bankAccount={bankAccount}
                    handleCopyData={handleCopyData}
                  />
                ))
                .reduce((accu, elem) => {
                  return accu === null
                    ? [elem]
                    : [
                        ...accu,
                        <div
                          key={`key${accu.length}`}
                          className={classes.separatorBottom}
                        />,
                        elem,
                      ];
                }, null)
            ) : (
              <Container className={classes.emptyContainer}>
                <Typography variant="body1" component="div">
                  No hay cuentas Xepelin para mostrar
                </Typography>
              </Container>
            )}
          </Panel>
          <Panel
            title="Mis cuentas"
            variant="green-title"
            titlePadding="xl xl lg"
            contentPadding="zero zero xl zero"
            titleAction={
              <Tooltip title="Aquí es donde te haremos la transferencia del dinero de tu financiamiento.">
                <HelpOutlineRoundedIcon color="secondary" fontSize="small" />
              </Tooltip>
            }
            actions={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Grid item xs={6} align="right">
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() =>
                    setShowBankAccountDialog(!showBankAccountDialog)
                  }
                  data-qa="user-bank-accounts-agregar-cuenta-nueva-button-action"
                >
                  Agregar cuenta nueva
                </Button>
              </Grid>
            }
          >
            {bankAccounts?.length ? (
              bankAccounts
                .filter(bankAccount => !bankAccount.isXepelin)
                .map(bankAccount => (
                  <BankAccountItem
                    key={`item-${bankAccount.id}`}
                    bankAccount={bankAccount}
                    handleCopyData={handleCopyData}
                    handleShowEditDialog={handleShowEditDialog}
                    handleShowDeleteAccountDialog={
                      handleShowDeleteAccountDialog
                    }
                    handleShowDefaultAccountDialog={
                      handleShowDefaultAccountDialog
                    }
                  />
                ))
                .reduce((accu, elem) => {
                  return accu === null
                    ? [elem]
                    : [
                        ...accu,
                        <div
                          key={`key${accu.length}`}
                          className={classes.separatorBottom}
                        />,
                        elem,
                      ];
                }, null)
            ) : (
              <Container className={classes.emptyContainer}>
                <Typography variant="body1" component="div">
                  No hay cuentas bancarias para mostrar
                </Typography>
              </Container>
            )}
          </Panel>
        </Box>
      </Grid>
    </Fade>
  );
};

export default UserBankAccounts;
