import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, Grid, Typography, Box } from '@material-ui/core';
import { AVAILABLE_COUNTRIES } from '../helpers/constants';
import PaymentsInvoiceTable from './tables/PaymentsInvoiceTable';
import PaymentsInvoiceTableToolbar from './elements/PaymentsInvoiceTableToolbar';
import Card from './elements/Card';
import {
  paymentsFetchInvoices,
  paymentsRestoreInvoice,
  paymentsSetPagination,
} from '../store/actions/paymentsActions';

const TABLE_NAME = 'paid';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
  },
  tableInvoicesContainer: {
    alignContent: 'flex-start',
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    height: '100%',
  },
  invoicePayrollColumn: {
    minWidth: 160,
  },
}));

const PaymentsPaidTable = React.memo(props => {
  const { countryId, businessId } = props;
  const { pagination } = useSelector(state => state.paymentsState);
  const { page, pageSize } = pagination;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [search, setSearch] = useState(null);

  // eslint-disable-next-line no-unused-vars
  /* const downloadInvoice = ({ invoice, closeMenu }) => {
    closeMenu();
  }; */

  // eslint-disable-next-line no-unused-vars
  /* const emailInvoice = ({ invoice, closeMenu }) => {
    closeMenu();
  }; */

  const rows = [
    {
      hide: true,
      field: 'paymentDetail.createdAt',
    },
    {
      position: 55,
      title: 'Nomina',
      field: 'payroll',
      type: 'string',
      align: 'left',
      hide: false,
      render: invoice => (
        <Typography
          variant="body1"
          color="textPrimary"
          component="div"
          className={classes.invoicePayrollColumn}
        >
          <Box fontWeight="fontWeightBold">
            {invoice?.payroll?.name.toUpperCase()}
          </Box>
        </Typography>
      ),
    },
  ];

  const actionsButtons = [
    {
      text: 'Devolver a mis facturas',
      action: ({ invoice, closeMenu }) => {
        dispatch(paymentsRestoreInvoice(businessId, [invoice], 'paid'));
        closeMenu();
      },
    },
    /* {
      text: 'Descargar comprobante',
      action: downloadInvoice,
    },
    {
      text: 'Enviar por correo',
      action: emailInvoice,
    }, */
  ];

  const handleSearchChange = value => {
    dispatch(
      paymentsSetPagination({
        ...pagination,
        page: 1,
      })
    );
    setSearch(value);
  };

  const handleUpdateInvoices = (page, pageSize) => {
    dispatch(
      paymentsFetchInvoices({
        businessId,
        option: TABLE_NAME,
        pageSize,
        page: page + 1,
        search,
      })
    );
  };

  useEffect(() => {
    dispatch(
      paymentsFetchInvoices({
        businessId,
        option: TABLE_NAME,
        pageSize,
        page,
        search,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch]);

  return (
    <Grid container item className={classes.tableInvoicesContainer}>
      <PaymentsInvoiceTableToolbar
        title="Revisa el historial de facturas pagadas"
        onChangeSearch={handleSearchChange}
      />

      <Card variant="white" border className={classes.card}>
        <PaymentsInvoiceTable
          rows={rows}
          actionsButtons={actionsButtons}
          countryId={countryId}
          handleUpdateInvoices={handleUpdateInvoices}
        />
      </Card>
    </Grid>
  );
});

PaymentsPaidTable.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  businessId: PropTypes.number.isRequired,
};

export default PaymentsPaidTable;
