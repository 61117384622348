import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const getFirstBusinessDayFrom = (startDate, holidays = []) => {
  if (!startDate) return startDate;

  const dateToEvaluate = moment(startDate);
  const isHoliday = holidays.some(h =>
    dateToEvaluate.isSame(moment(h.date), 'day')
  );
  if (
    dateToEvaluate.isoWeekday() !== 6 &&
    dateToEvaluate.isoWeekday() !== 7 &&
    !isHoliday
  ) {
    return startDate;
  }
  return getFirstBusinessDayFrom(
    dateToEvaluate.add(1, 'day').format('YYYY-MM-DD')
  );
};

export const isWeekend = date => {
  const dayOfWeek = date.format('E');
  return dayOfWeek === '6' || dayOfWeek === '7';
};
