import axios from 'axios';
import {
  PAYMENTS_SET_ACTIVE_TAB,
  PAYMENTS_HANDLE_INVOICES_SELECTION_CHANGE,
  PAYMENTS_CLEAR_INVOICES_SELECTION_CHANGE,
  PAYMENTS_SET_PAGINATION,
  FETCH_PAYMENTS_INVOICES_START,
  FETCH_PAYMENTS_INVOICES_SUCCESS,
  FETCH_PAYMENTS_INVOICES_ERROR,
  ARCHIVE_PAYMENTS_INVOICES_START,
  ARCHIVE_PAYMENTS_INVOICES_SUCCESS,
  ARCHIVE_PAYMENTS_INVOICES_ERROR,
  PAY_PAYMENTS_INVOICES_START,
  PAY_PAYMENTS_INVOICES_SUCCESS,
  PAY_PAYMENTS_INVOICES_ERROR,
  RESTORE_PAYMENTS_INVOICES_START,
  RESTORE_PAYMENTS_INVOICES_ERROR,
  CHANGE_PAYMENT_DATE_PAYMENTS_START,
  CHANGE_PAYMENT_DATE_PAYMENTS_ERROR,
  SAVE_PAYROLL_START,
  SAVE_PAYROLL_SUCCESS,
  SAVE_PAYROLL_FAILURE,
} from './types';
import { t13s } from '../../translationKeys';
import { enqueueSnackbar } from './notificationActions';

import { paymentsFetchStats } from './paymentsStatsActions';

export const setActiveTab = tab => dispatch => {
  dispatch({ type: PAYMENTS_SET_ACTIVE_TAB, payload: tab });
};

export const paymentsHandleInvoicesSelectionChange =
  ({ checkedInvoices, nonCheckedInvoices }) =>
  dispatch => {
    dispatch({
      type: PAYMENTS_HANDLE_INVOICES_SELECTION_CHANGE,
      payload: { checkedInvoices, nonCheckedInvoices },
    });
  };

export const paymentsClearInvoicesSelectionChange = () => dispatch => {
  dispatch({
    type: PAYMENTS_CLEAR_INVOICES_SELECTION_CHANGE,
  });
};

export const paymentsSetPagination = pagination => dispatch => {
  dispatch({
    type: PAYMENTS_SET_PAGINATION,
    payload: { ...pagination },
  });
};

export const paymentsFetchInvoices =
  ({ businessId, option, payrollId, pageSize, page, search }) =>
  dispatch => {
    dispatch({ type: FETCH_PAYMENTS_INVOICES_START });
    axios
      .get(`/api/payment/business/${businessId}/invoice`, {
        params: {
          option,
          payrollId,
          page,
          size: pageSize,
          search,
        },
      })
      .then(res => {
        const { data: invoices, pagination = {} } = res.data;
        dispatch({
          type: FETCH_PAYMENTS_INVOICES_SUCCESS,
          payload: { invoices, pagination },
        });
      })
      .catch(err => {
        dispatch({ type: FETCH_PAYMENTS_INVOICES_ERROR, payload: err });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.PAYMENTS_FETCH_ERROR,
            options: {
              variant: 'error',
            },
          })
        );
      });
  };

export const paymentsArchiveInvoice =
  (businessId, invoices, archivedAt) => (dispatch, getState) => {
    const { pageSize, page } = getState().paymentsState.pagination;
    const invoiceIds = invoices.map(invoice => invoice.id);
    if (invoices.length !== 0) {
      dispatch({ type: ARCHIVE_PAYMENTS_INVOICES_START });
      axios
        .put(`api/payment/business/${businessId}/invoice/archive`, {
          archivedAt,
          invoiceIds,
        })
        .then(() => {
          dispatch({ type: ARCHIVE_PAYMENTS_INVOICES_SUCCESS });
          dispatch(
            paymentsFetchInvoices({
              businessId,
              option: 'inbox',
              pageSize,
              page,
            })
          );
          dispatch(paymentsFetchStats(businessId));
        })
        .catch(err => {
          dispatch({ type: ARCHIVE_PAYMENTS_INVOICES_ERROR, payload: err });
          dispatch(
            enqueueSnackbar({
              message: t13s.NOTIFICATION.PAYMENTS_ARCHIVE_ERROR,
              options: {
                variant: 'error',
              },
            })
          );
        });
    } else {
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.PAYMENTS_NO_SELECTED_INVOICES,
          options: {
            variant: 'warning',
          },
        })
      );
    }
  };

export const paymentsPayInvoice =
  (businessId, invoices, paidAt) => (dispatch, getState) => {
    const { pageSize, page } = getState().paymentsState.pagination;
    const invoiceIds = invoices.map(invoice => invoice.id);
    if (invoices.length !== 0) {
      dispatch({ type: PAY_PAYMENTS_INVOICES_START });
      axios
        .put(`api/payment/business/${businessId}/invoice/pay`, {
          paidAt,
          invoiceIds,
        })
        .then(() => {
          dispatch({ type: PAY_PAYMENTS_INVOICES_SUCCESS });
          dispatch(
            paymentsFetchInvoices({
              businessId,
              option: 'inbox',
              pageSize,
              page,
            })
          );
          dispatch(paymentsFetchStats(businessId));
        })
        .catch(err => {
          dispatch({ type: PAY_PAYMENTS_INVOICES_ERROR, payload: err });
          dispatch(
            enqueueSnackbar({
              message: t13s.NOTIFICATION.PAYMENTS_PAY_ERROR,
              options: {
                variant: 'error',
              },
            })
          );
        });
    } else {
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.PAYMENTS_NO_SELECTED_INVOICES,
          options: {
            variant: 'warning',
          },
        })
      );
    }
  };

export const paymentsRestoreInvoice =
  (businessId, invoices, fromTable) => (dispatch, getState) => {
    const { pageSize, page } = getState().paymentsState.pagination;
    const invoiceIds = invoices.map(invoice => invoice.id);
    dispatch({ type: RESTORE_PAYMENTS_INVOICES_START });
    axios
      .put(`api/payment/business/${businessId}/invoice/restore`, {
        invoiceIds,
      })
      .then(() => {
        dispatch(
          paymentsFetchInvoices({
            businessId,
            option: fromTable,
            pageSize,
            page,
          })
        );
        dispatch(paymentsFetchStats(businessId));
      })
      .catch(err => {
        dispatch({ type: RESTORE_PAYMENTS_INVOICES_ERROR, payload: err });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.PAYMENTS_RESTORE_ERROR,
            options: {
              variant: 'error',
            },
          })
        );
      });
  };

export const paymentsChangePaymentDate =
  (businessId, invoiceId, newDate, fromTable) => (dispatch, getState) => {
    dispatch({ type: CHANGE_PAYMENT_DATE_PAYMENTS_START });
    axios
      .put(`api/payment/business/${businessId}/invoice/${invoiceId}`, {
        paymentDate: newDate,
      })
      .then(() => {
        const { pageSize, page } = getState().paymentsState.pagination;
        dispatch(
          paymentsFetchInvoices({
            businessId,
            option: fromTable,
            pageSize,
            page,
          })
        );
        dispatch(paymentsFetchStats(businessId));
      })
      .catch(err => {
        dispatch({ type: CHANGE_PAYMENT_DATE_PAYMENTS_ERROR, payload: err });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.PAYMENTS_CHANGE_PAYMENT_DATE_ERROR,
            options: {
              variant: 'error',
            },
          })
        );
      });
  };

export const paymentsSavePayroll =
  (businessId, name, paymentDate, invoiceIds, nextStep) =>
  (dispatch, getState) => {
    dispatch({ type: SAVE_PAYROLL_START });
    const { pageSize, page } = getState().paymentsState.pagination;
    axios
      .post(`/api/payment/business/${businessId}/payroll`, {
        name,
        paymentDate,
        invoiceIds,
      })
      .then(() => {
        dispatch({
          type: SAVE_PAYROLL_SUCCESS,
        });
        dispatch(paymentsFetchStats(businessId));
        dispatch(
          paymentsFetchInvoices({
            businessId,
            option: 'inbox',
            pageSize,
            page,
          })
        );
        nextStep();
      })
      .catch(err => {
        dispatch({ type: SAVE_PAYROLL_FAILURE, payload: err });
        dispatch(
          enqueueSnackbar({
            message: 'No se pudo programar la(s) factura(s).',
            options: {
              variant: 'error',
            },
          })
        );
        dispatch(paymentsFetchStats(businessId));
      });
  };
